$border-color-light: #666;

[data-widget-host='we-mobilize'] {
	.wem {
	  transition: all 1s;

	  .wem__map-container, .wem__events-right{
	    visibility: hidden;
	    opacity: 0  !important;
	    transition: opacity 0.5s;
	  }

	  &.--init, &.--failed{
	    .wem__map-container, .wem__events-right{
	      visibility: visible;
	      opacity: 1 !important;
	    }
	    visibility: visible;
	    opacity: 1;
	  }

		@include breakpoint($bp-bobby) {
			min-height: calc(100vh - $header-height);
			height: 100vh;
		}
	}

	.wem__search-bar__input {
		//height: auto;
		//padding: 20px 15px;
		font-family: $primary-font;
		border: none;
		font-size: 20px;

		&:focus {
			background-color: #fff;
		}
	}
	.wem_events-filters{
		label{
			font-size: 13.2px;
	    font-family: $headline-font;
	    font-weight: bold;
	    color: $border-color-light;
		}
		input, select{
			font-size: 14px;
			line-height: 3.21;
		}
	}


	.wem__events-panel {
		min-height: 600px;
	}

	.wem__events-panel__headline {
		@extend h3;
	  font-weight: bolder;
	  margin-bottom: 10px;
		font-size: 18px;
		color: $headlineColor;
	}

	.wem__events-panel__description {
		font-family: $primary-font;
	  color: $body;
	  font-size: 16px;
	  line-height: 1.88;
	}

	.wem__event-detail__content {
		font-family: $primary-font;
		color: #000;
	}

	.wem__event-card{
		border-left-color: $primary;
	}

	.wem__event-card__date {
		font-weight: 700;
		font-size: 13px;
	}

	.wem__event-card__title {
		@extend h5;
		margin-bottom:10px;
	}

	.wem .wem__event-card__title {
		color: $primary;
	}

	.wem__event-card__meta {
		font-family: $primary-font;
		font-size: 13px;
		line-height: 18px;
		margin-bottom:5px;
	}

	.wem__event-card__actions {
		margin-top: 15px;
	}
	.wem__event-actions__url{
		font-family: $headline-font;
		&.wem__event-actions__url-rsvp{
			color: $secondary;
		}
		&.wem__event-actions__url-details{
			color: $primary;
		}
	}


	$inactive-map-icon-color: $primary;
	$active-map-icon-color: $secondary;


	.wem__events-map__marker{
		background-color: $inactive-map-icon-color;
	  &:hover{
	    background-color: $active-map-icon-color;
	  }
	  &.is-active{
	    background-color: $active-map-icon-color;
	  }
	}
	.wem__events-map__icon{
		color: $active-map-icon-color;
	  &:hover{
	    color: $inactive-map-icon-color;
	  }
	  &.is-active{
	    color: $inactive-map-icon-color;
	  }
	}
	.wem_event-card__priority-icon{
		color: $primary;
	}

	.wem__filter--category-field, .wem__filter--date-field{
		border-color: #ccc;//$border-color-light;
	}

	.wem__event-detail__close {
		line-height: 0;

		i {
			@include transform(translateY(-1px));
		}
	}

	.wem__event-detail__date {
		@extend h6;
		font-size: 14px;
	}

	.wem .wem__event-detail__date {
		margin-bottom: 13px;
	}

	.wem__event-detail__title {
		@extend h3;
	}

	.wem .wem__event-detail__title {
		margin-bottom: 10px;
	}

	.wem__event-detail__meta {
		font-weight: 400;
		font-size: 18px;
	}
	.wem__event-card__description{
		font-size: 16px;
		line-height: 1.38;
	}
	.wem__events-panel__scrollmore{
		&:before,&:after{
			opacity: 0.8;
		}
		&:before{
			font-family: $headline-font;
			font-size: 14px;
  		font-weight: 500;
		}
		&:after{
			top:2px;
		}
		svg{
			//often needs to be positioned to fit the font x-height
		}
	}

}


.wem-mobilize-back-btn{
	margin-bottom:-20px;
	text-align:center;
	a {
		font-family: $headline-font;
		font-weight: 600;
		font-size: 16px;
		color: #666;
	}
	svg{
		height:13px;
		max-width:8.75px;
		display: inline-block;
		margin-right:10px;
		position:relative;
		top: -2.5px;
	}
}