.single-poster {
  position: relative;
  padding: 30px 0;
  .close-page {
    position: absolute;
    top: 18px;
    right: 18px;
    height: 32px;
    width: 32px;
    @include breakpoint($bp-charlie) {
      top: 68px;
      right: 65px;
      height: 41px;
      width: 41px;
    }
    a {
      svg {
        width: 32px;
        height: 32px;
        @include breakpoint($bp-charlie) {
          width: 41px;
          height: 41px;
        }
        .st1 {
          fill: #c7c7c7;
          @include hover-fade();
        }
        .st0 {
          fill: #c7c7c7;
          @include hover-fade();
        }
      }
      &:hover {
        svg {
          .st1,
          .st0 {
            fill: $secondary !important;
          }
        }
      }
    }
  }
  .post-video {
    position: relative;
    padding-bottom: 56.25%;
    flex: 0 0 auto;
    width: 100%; 
    height: 0;
    margin-top: 36px;
    @include breakpoint($bp-charlie) {
      margin-top: 26px;
    }
    @include breakpoint(1040px) {
      width: 94%; 
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  .post-info {
    max-width: 1300px;
    margin: 0 auto;
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include breakpoint($bp-charlie) {
      padding-left: 40px;
      padding-right: 120px;
    }
    .info {
      width: 100%;
      padding: 25px 0 35px;
      display: inline-block;
      flex-grow: 1;
      @include breakpoint(450px) {
        padding: 25px 5% 40px 1%;
      }
      @include breakpoint($bp-charlie) {
        width: 35%;
        padding: 40px 5% 40px 1%;
      }
      h3 {
        font-family: $primary-font;
        font-size: 17px;
        line-height: 130%;
        color: #000000;
        font-weight: 700;
        margin-bottom: 4px;
      }
      .description {
        p {
          font-family: $primary-font;
          font-size: 17px;
          line-height: 130%;
          color: #000000;
          font-weight: 400;
          margin-bottom: 10px;
          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      .artist {
        margin-top: 30px;
        p {
          margin-bottom: 0;
          font-size: 15px;
          font-weight: 700;
          line-height: 135%;
          color: #000;
          a {
            color: #000;
            &:hover {
              color: $secondary;
              text-decoration: underline;
            }
          }
        }
      }
    }
    .poster {
      width: 100%;
      display: inline-block;
      flex-grow: 1;
      @include breakpoint(450px) {
        width: 80%;
      }
      @include breakpoint($bp-charlie) {
        width: 50%;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    .share {
      width: 100%;
      padding: 35px 0;
      display: inline-block;
      flex-grow: 1;
      @include breakpoint(450px) {
        width: 10%;
        padding: 35px 3% 0;
      }
      @include breakpoint(680px) {
        width: 13%;
        padding: 35px 3% 0;
      }
      a {
        display: inline-block;
        float: none;
        color: #000;
        margin-bottom: 0;
        margin-right: 20px;
        @include breakpoint(450px) {
          display: block;
          margin-bottom: 20px;
          margin-right: 0;
        }
        &:hover {
          color: $primary;
        }
        i {
          font-size: 24px;
          &.fa-twitter {
            font-size: 26px;
          }
        }
        svg {
          width: 24px;
          height: 24px;
          margin-top: -10px;
          @include breakpoint(450px) {
            margin-top: 0;
          }
        }
      }
      .heart {
        margin-top: 0;
        display: inline-block;
        @include breakpoint(450px) {
          margin-top: 30px;
          display: block;
        }
        .heart-this-wrap {
          top: 2px;
          @include breakpoint(450px) {
            top: 0;
          }
          .heart-this {
            span {
              float: right;
              margin-top: -22px;
              width: 33px;
              text-align: center;
              padding-top: 2px;
              @include breakpoint(450px) {
                margin-top: 0;
                float: left;
                margin-left: -3px;
              }
            }
            &:before {
              position: relative;
              width: 24px;
              height: 24px;
              background-size: 100% !important;
              vertical-align: none !important;
              display: block !important;
              margin-left: 0 !important;
            }
          }
        }
      }
    }
  }
  &.single-poster--video {
    .post-info .info {
      width: 90%;
      padding-bottom: 0;
      @include breakpoint(680px) {
        width: 85%;
        padding-bottom: 40px;
      }
      @include breakpoint($bp-charlie) {
        
      }
    }
  }
}
