.is-splashed {
  pointer-events: none;
  position: relative;

  &:before {
    background: #0b0b0b;
    content: '';
    height: 100vh;
    left: 0;
    position: fixed;
    opacity: 0.8;
    top: 0;
    width: 100vw;
    z-index: 9997;
  }
}

#image-popup-inline,
#inline-popup {
  position: relative;
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
}

#image-popup-inline a {
  display: block;
  margin: 0 auto;
  width: 100%;
  position: relative;
  max-width: 600px;
}

#image-popup-inline a img {
  height: auto;
  width: 100%;
}

#image-popup-inline button.mfp-close,
#inline-popup button.mfp-close {
  color: #fff;
  top: -44px;
  right: -13px;
}

.hide-me {
  display: none;
}

#inline-popup .inline-wrap {
  background: #fff;
  padding: 15px;
  text-align: center;
  &--action {
    text-align: left;
    padding: 45px;
    h1 {
      line-height: 1;
    }
  }
}

#inline-popup .btn {
  text-align: center;
  color: #e49b30;
  background: #212121;
  min-width: 90px;
  min-width: 190px;
  width: auto;
  display: inline-block;
  font-family: Rubik,sans-serif;
  &:hover {
    background: #e49b30;
    color: #212121;
  }
}

//animation
/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;

  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}
/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;

  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.pop-over.video {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 9999;
  background: rgba(#0b0b0b, 0.8);
  .videoWrapper {
    height: 100vh;
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
    .mfp-close {
      top: 50%;
      @include transform(translateY(calc(-50% - 30vh - 20px)));
    }
    iframe {
      height: 60vh;
      width: 100%;
      top: 50%;
      @include transform(translateY(-50%));
    }
    @include breakpoint($bp-charlie) {
      width: 70vw;
    }
  }
  .play-row {
    width: 100%;
    height: 100px;
    margin-top: 0px;
    top: 50%;
    position: relative;
    @include transform(translateY(-50%));
    .play-button {
      position: relative;
      margin: 0 auto;
      display: table;
      float: none;
      left: auto;
      top: 0;
      cursor: pointer;
      width: 60px;
      height: 60px;
      transition: 0.3s;
      .play-bg {
        background: #fff;
        width: 25px;
        height: 25px;
        top: 18px;
        left: 20px;
        position: absolute;
        transition: 0.3s;
        overflow: hidden;
      }
      svg {
        z-index: 9;
        position: relative;
      }
      @include breakpoint($bp-charlie) {
        margin: 10px auto 0;
        top: 0;
        width: 90px;
        height: 90px;
        .play-bg {
          width: 40px;
          height: 40px;
          top: 23px;
          left: 28px;
        }
      }
    }
  }
}
