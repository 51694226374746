.module {
  float: left;
  width: 100%;
  position: relative;
  margin-top: 40px;

  @include breakpoint($bp-charlie) {
    margin-top: 55px;
  }
  @include breakpoint($bp-bobby) {
    margin-top: 60px;
  }
  &.last-module {
    margin-bottom: 40px;
    .issues-page hr.divider {
      display: none;
    }
    @include breakpoint($bp-charlie) {
      margin-bottom: 70px;
    }
    @include breakpoint($bp-bobby) {
      margin-bottom: 90px;
    }
  }
  &.press-hits .col-md-8 {
    margin-bottom: 40px !important;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .press__item {
    
    a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      position: relative;
    }
    img {
      width: 25%;
      flex: 0 0 auto;
      max-height: 75px;
      object-fit: contain;
      margin-bottom: 20px;
    }
    h4 {
      width: 100%;
      flex: 0 0 auto;
      display: inline-block;
      @include hover-fade;
      @include breakpoint($bp-charlie) {
        width: 75%;
        padding-left: 40px;
      }
    }
    a:hover h4 {
      color: #f95f2f;
    }
  }
  &.buttons {
    margin-top: 20px;
    @include breakpoint($bp-charlie) {
      margin-top: 20px;
    }
    @include breakpoint($bp-bobby) {
      margin-top: 20px;
    }
  }
  .list-grid {
    .single-row {
      margin-bottom: 40px;
    }
    h5 {
      text-transform: none;
      @include breakpoint(992px) {
        font-size: 20px;
      }
    }
    .description {
      font-size: 16px;
      margin-bottom: 10px;
    }
    .links a {
      font-size: 16px;
      font-weight: 600;
      margin-right: 12px;
    }
  }
  .container,
  .row {
    height: 100%;
  }
  img.size-full {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  .wp-caption {
    width: 100% !important;
    margin: 25px 0 25px;
    img {
      height: auto !important;
      width: 100% !important;
      @include breakpoint($bp-bravo) {
        height: auto !important;
        width: auto !important;
      }
    }
    @include breakpoint($bp-bravo) {
      width: auto !important;
      margin: 25px 0 25px;
    }
  }
  .wp-caption-text {
    margin: 10px 0px;
    font-size: 14px;
    line-height: 125%;
    text-align: center;
    font-family: $primary-font;
    font-weight: 400;
    color: $body;
    width: 100% !important;
    @include breakpoint($bp-bravo) {
      font-size: 16px;
      width: auto;
    }
  }
  .alignleft {
    float: none;
    margin: 25px 0 25px;
    max-width: 100%;
    width: 100%;
    height: auto;
    @include breakpoint($bp-bravo) {
      float: left;
      width: auto;
      max-width: auto;
      position: relative;
      margin: 10px 35px 10px 0;
    }
  }
  .aligncenter {
    float: none;
    display: table;
    margin: 0 auto;
    width: 100%;
    height: auto;
    margin: 25px 0 25px;
    @include breakpoint($bp-bravo) {
      float: none;
      width: auto;
      position: relative;
      margin: 10px auto;
    }
  }
  .alignright {
    float: none;
    margin: 25px 0 25px;
    max-width: 100%;
    width: 100%;
    height: auto;
    @include breakpoint($bp-bravo) {
      float: right;
      max-width: auto;
      width: auto;
      position: relative;
      margin: 10px 0 10px 35px;
    }
  }
  &.action-menu {
    margin: 0px;
    position: fixed;
    bottom: 0px;
    z-index: 10;
    @include breakpoint($bp-charlie) {
      position: relative;
    }
    nav {
      ul {
        @for $i from 1 through 8 {
          li:first-child:nth-last-child(#{$i}),
          li:first-child:nth-last-child(#{$i}) ~ li {
            width: 100% / $i;
          }
        }
        li {
          text-align: center;
          border-right: 1px solid $primary;
          float: left;
          .sub-menu {
            display: none;
          }
          &:last-child {
            margin: 0px;
            border: 0px;
          }
          a {
            display: block;
            float: left;
            width: 100%;
            background: $secondary;
            color: #fff;
            text-transform: uppercase;
            padding: 20px 0;
            @include breakpoint($bp-charlie) {
              padding: 60px 0;
            }
          }
        }
      }
    }
  }
  &.full-cta {
    float: left;
    width: 100%;
    background-color: $primary;
    color: #fff;
    background-size: cover !important;
    @include breakpoint($bp-charlie) {
      height: 650px;
    }
    .btn {
      float: left;
      width: 100%;
      @include breakpoint($bp-charlie) {
        margin: 0 auto;
        display: table;
        float: none;
        width: auto;
      }
    }
    p {
      color: #fff;
      max-width: 750px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
  }
  &.donation {
    float: left;
    width: 100%;
    background-color: $body;
    color: #fff;
    background-size: cover !important;
    margin-bottom: 25px;
    padding: 85px 0;
    @include breakpoint($bp-charlie) {
      padding: 190px 0;
    }
    .btn {
      float: left;
      width: 100%;
      @include breakpoint($bp-charlie) {
        margin: 0 auto;
        display: table;
        float: none;
        width: auto;
      }
    }
    p {
      color: #fff;
      max-width: 750px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }
    .inner-form {
      max-width: 300px;
      width: 100%;
      display: table;
      margin: 0 auto;
      input {
        width: 100%;
        margin-bottom: 4px;
      }
      input[name='zip'] {
        margin-bottom: 13px;
      }
      .btn {
        width: 100% !important;
      }
    }
  }
  &.news-feed {
    margin: 0;
    padding: 50px 20px;
    @include breakpoint($bp-charlie) {
      padding: 85px 40px;
    }
    @include breakpoint($bp-bobby) {
      padding: 125px 40px;
    }
    h3 {
      text-align: center;
      margin: 0 0 35px 0;
      @include breakpoint($bp-charlie) {
        margin: 0 0 45px 0;
      }
      @include breakpoint($bp-bobby) {
        margin: 0 0 55px 0;
      }
    }
    .container {
      padding: 0;
    }
    .read-more {
      float: none;
      margin: 0 auto;
      display: table;
      text-align: center;
      a {
        display: table;
      }
    }
    .col-md-8 {
      float: none;
      display: table;
      margin: 0 auto;
    }
    .news-items {
      float: left;
      width: 100%;
      margin: 0 0 45px 0;
      @include breakpoint($bp-charlie) {
        margin: 0 0 55px 0;
      }
      @include breakpoint($bp-bobby) {
        margin: 0 0 75px 0;
      }
      //this class controls the order so that tweets are staggered ww/ featured posts
      .supports-flex-wrap & {
        &.stagger-tweets {
          display: flex;
          flex-wrap: wrap;
          .featured-post {
            order: 1;
            & + .featured-post {
              order: 3;
            }
          }
          .twitter {
            order: 2;
            & + .twitter {
              order: 4;
            }
          }
          @include breakpoint($bp-charlie) {
            .featured-post {
              order: 1;
              & + .featured-post {
                order: 4;
              }
            }
            .twitter {
              order: 2;
              & + .twitter {
                order: 3;
              }
            }
          }
        }
      }
      .news-item {
        float: none;
        width: 100%;
        margin: 6px auto;
        background: #000000;
        border-radius: $borderRadius;
        @include box-shadow(0px, 6px, 30px, 0px, rgba(0, 0, 0, 0.15));
        height: 240px;
        position: relative;
        @include hover-fade();
        overflow: hidden;
        @include breakpoint(375px) {
          height: 295px;
        }
        @include breakpoint(414px) {
          height: 400px;
        }
        @include breakpoint($bp-charlie) {
          background: #fff;
          width: calc(50% - 3%);
          height: 475px;
          float: left;
          margin: 1.5%;
          max-width: 350px;
          max-width: 100%;
        }
        @include breakpoint($bp-bobby) {
          height: 555px;
          max-width: 100%;
        }

        $moduleNewsFeed: &;
        @at-root {
          .activeSection#{$moduleNewsFeed} {
            will-change: transform, background-color;
          }
        }

        &:hover {
          background: #000;
          @include transform(translateY(-6px));
          @include box-shadow(0px, 6px, 30px, 0px, rgba(0, 0, 0, 0.15));
          &.facebook,
          &.twitter,
          &.featured-post {
            &.image-in.half {
              h5,
              small {
                color: #fff;
                opacity: 1;
              }
              .news-image {
                opacity: 0.5;
                @include breakpoint($bp-charlie) {
                  opacity: 1;
                }
              }
              .news-content {
                .text-container {
                  @include transform(translateY(0%));
                }
              }
            }

            &.image-in.full,
            &.image-in.video {
              h5,
              small {
                opacity: 1;
              }
              .news-image {
                opacity: 0.5;
              }
              .news-content {
                .text-container {
                  @include transform(translateY(0%));
                }
              }
            }
          }
          .news-image .image {
            opacity: 0.5;
          }
          .play-icon {
            display: none;
            @include breakpoint(414px) {
              display: block;
            }
          }
          @include breakpoint($bp-charlie) {
            background: $secondary;
          }
        }
        a {
          position: absolute;
          left: 0px;
          top: 0px;
          right: 0px;
          bottom: 0px;
          z-index: 199 !important;
        }
        .video-link {
          height: 0;
          z-index: 200 !important;
          @include breakpoint($bp-charlie) {
            height: 50%;
          }
        }
        .news-image {
          position: absolute;
          top: 0px;
          left: 0px;
          height: 100%;
          width: 100%;
          z-index: 1;
          background: #000;
          background-position: 50% 50% !important;
          @include hover-fade();
          //opacity: .5;
          .image {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            height: 100%;
            width: 100%;
            background-size: cover !important;
            background-position: 50% 50% !important;
            @include hover-fade();
            //opacity: .5;
          }
          @include breakpoint($bp-charlie) {
            height: 50%;
          }
        }
        .message {
          @include hover-fade();
          margin: 15px 0;
          word-wrap: break-word;
          @include breakpoint($bp-charlie) {
            margin: 20px 0;
          }
          @include breakpoint($bp-bobby) {
            margin: 30px 0;
          }
        }
        .icon {
          position: absolute;
          right: 0;
          padding: 30px;
          i {
            font-size: 28px;
            @include hover-fade();
            color: $primary;
            &.fa-facebook {
              font-size: 26px;
            }
          }
          svg {
            fill: $body;
            width: 28px;
            height: auto;
            @include hover-fade();
          }
        }
        .play-icon {
          width: 50px;
          z-index: 9;
          position: relative;
          top: 50%;
          left: 50%;
          @include transform(translateX(-50%) translateY(-50%));
          svg {
            fill: #fff;
            -webkit-filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0px 2px 3px rgba(0, 0, 0, 0.25));
          }
        }
        .news-content {
          position: absolute;
          height: 100%;
          top: 0px;
          left: 0px;
          right: 0px;
          padding: 0px;
          z-index: 2;
          .text-container {
            position: absolute;
            bottom: 0;
            left: 0;
            padding: 25px 40px 20px 25px;
            width: 100%;
            @include breakpoint($bp-bravo) {
              padding: 55px 90px 55px 55px;
            }
            @include breakpoint($bp-charlie) {
              padding: 35px 70px 30px 35px;
            }
            @include breakpoint($bp-bobby) {
              padding: 55px 90px 55px 55px;
            }
          }
        }
        small {
          color: #fff;
          @include hover-fade();
          margin-bottom: 0px;
          @include breakpoint($bp-charlie) {
            color: $body;
          }
        }
        &.image-in {
          .icon {
            i {
              color: #fff;
            }
            svg {
              fill: #fff;
            }
          }
          &.full,
          &.video {
            h5,
            small {
              color: #fff;
            }
          }
        }
        &.half.long-tweet {
          .text-container h5,
          .text-container h4 {
            @include breakpoint($bp-charlie) {
              font-size: 14px;
            }
            @include breakpoint($bp-delta) {
              font-size: 16px;
            }
          }
        }
        &.no-image {
          background: #fff;
          &:hover {
            background: $primary;
            h5,
            small,
            .icon i {
              color: #fff;
            }
            .icon svg {
              fill: #fff;
            }
          }
        }
        &.twitter {
          &.image-in {
            h5,
            small {
              color: #fff;
              @include breakpoint($bp-charlie) {
                color: $body;
              }
            }
            &.video,
            &.full {
              h5,
              small {
                color: #fff;
              }
            }
          }
          // &:nth-child(4), &:nth-child(5), &:nth-child(6) {
          // 	display: none;//we should have functions output exactly what we want without this backstop
          // }
        }
        &.facebook,
        &.twitter,
        &.featured-post {
          &.image-in.half {
            h5,
            small {
              opacity: 0;
              @include hover-fade();
              @include breakpoint($bp-charlie) {
                opacity: 1;
              }
            }
            .news-content {
              .text-container {
                @include transform(translateY(25%));
                @include hover-fade();
                @include breakpoint($bp-charlie) {
                  @include transform(translateY(0%));
                }
              }
            }
          }
          &.image-in.full,
          &.image-in.video {
            background: #000000;

            h5,
            small {
              opacity: 0;
              @include hover-fade();
            }
            .news-content {
              .text-container {
                @include transform(translateY(25%));
                @include hover-fade();
              }
            }
          }
          .news-image {
            background-size: cover !important;
            &.full {
              height: 100%;
            }
          }
        }
      }
    }
    .btn {
      margin: 0 auto;
      display: table;
      float: none;
      width: auto;
    }
  }
  &.featured-content {
    float: left;
    width: 100%;
    .image-slider {
      float: left;
      width: 100%;
      position: relative;
      padding: 0;
      overflow: hidden;
      .slick-prev {
        position: absolute;
        top: 37%;
        @include transform(translateY(-50%));
        left: 25px;
        width: 25px;
        height: 25px;
        -webkit-mask: url('img/next.svg') no-repeat 100% 100%;
        mask: url('img/next.svg') no-repeat 100% 100%;
        -webkit-mask-size: cover;
        mask-size: cover;
        background-color: #333;
        @include hover-fade();
        text-indent: -9999px;
        cursor: pointer;
        @include transform(rotate(180deg));
        z-index: 25;
        opacity: 0.5;
        @include breakpoint($bp-charlie) {
          left: 100px;
          background-color: #c8c8c8;
          top: 42%;
          width: 70px;
          height: 70px;
        }
        &:hover {
          background: #333;
          opacity: 1;
        }
      }
      .slick-next {
        position: absolute;
        top: 42%;
        @include transform(translateY(-50%));
        right: 25px;
        width: 25px;
        z-index: 25;
        height: 25px;
        -webkit-mask: url('img/next.svg') no-repeat 100% 100%;
        mask: url('img/next.svg') no-repeat 100% 100%;
        -webkit-mask-size: cover;
        mask-size: cover;
        background-color: #333;
        @include hover-fade();
        text-indent: -9999px;
        opacity: 0.5;
        cursor: pointer;
        @include breakpoint($bp-charlie) {
          right: 100px;
          background-color: #c8c8c8;
          top: 47%;
          width: 70px;
          height: 70px;
        }
        &:hover {
          background: #333;
          opacity: 1;
        }
      }
      .featured-image {
        height: auto;
        width: 100%;
        float: left;
        outline: none;
        background-repeat: no-repeat;
        background-position: center 0;
        background-size: cover !important;
        @include breakpoint($bp-charlie) {
          height: auto;
        }
        @include breakpoint($bp-delta) {
          height: auto;
        }
      }
      .featured-image {
        margin-top: 0;
      }
    }
    .featured-image {
      text-align: center;
      img {
        width: 100%;
        max-width: 1145px;
        height: auto;
      }
      &.video-share {
        margin-top: 0;
        .videoWrapper {
          width: 100%;
          max-width: 1145px;
          height: auto;
          float: none;
          margin: 0 auto;
          @include breakpoint($bp-charlie) {
            min-height: 600px;
          }
          @include breakpoint($bp-bobby) {
            min-height: 700px;
          }
        }
      }
    }
    .col-md-8 {
      margin: 0 auto;
      display: table;
      float: none;
      text-align: left;
      margin-top: 15px;
      p {
        margin: 0;
        font-size: 14px;
        color: #666666;
        @include breakpoint($bp-charlie) {
          font-size: 16px;
        }
      }
    }
  }
  &.full-content {
    blockquote {
      text-align: left;
      margin: 35px 0;
      position: relative;
      padding-left: 20px;
      color: $body;
      font-size: 22px;
      line-height: 145%;
      font-family: $primary-font;
      font-weight: 400;
      font-style: italic;
      @include breakpoint($bp-charlie) {
        font-size: 25px;
      }
      @include breakpoint($bp-bobby) {
        font-size: 28px;
        padding-left: 0;
        margin: 55px 0;
      }
      &:before {
        content: '';
        position: absolute;
        width: 1px;
        height: calc(100% - 6px);
        background: #cccccc;
        border-radius: $borderRadius + 1px;
        margin: 0 auto;
        top: 8px;
        left: 0;
        @include breakpoint($bp-delta) {
          left: -40px;
        }
      }
      p {
        color: $body;
        font-size: 22px;
        line-height: 145%;
        font-family: $primary-font;
        font-weight: 400;
        font-style: italic;
        margin-bottom: 25px;
        @include breakpoint($bp-charlie) {
          font-size: 25px;
        }
        @include breakpoint($bp-bobby) {
          font-size: 28px;
        }
        &:last-child {
          padding: 0px;
          margin: 0px;
        }
      }
      h6 {
        font-style: normal;
      }
      img {
        max-width: 100%;
      }
    }
    .col-md-8 {
      margin: 0 auto;
      float: none;
    }
    .container {
      //max-width: 1140px;
    }
    img {
      width: 100%;
      @include breakpoint($bp-bravo) {
        width: auto;
      }
    }
    @include breakpoint($bp-bravo, max) {
      a {
        word-break: break-word;
      }
    }
  }
  &.issues-page {
    margin-top: 30px;
    @include breakpoint($bp-charlie) {
      margin-top: 45px;
    }
    @include breakpoint($bp-bobby) {
      margin-top: 50px;
    }
  }
  &.columns {
    .row {
      .indiv-column {
        float: left;
        width: 100%;
        @include breakpoint($bp-charlie) {
          padding: 0 15px;
        }
      }
      @include breakpoint($bp-charlie) {
        @for $i from 1 through 8 {
          .indiv-column:first-child:nth-last-child(#{$i}),
          .indiv-column:first-child:nth-last-child(#{$i}) ~ .indiv-column {
            width: 100% / $i;
          }
        }
      }
    }
  }
  &.video {
    .videoWrapper {
      @include breakpoint($bp-charlie) {
        width: calc(100% + 150px);
        margin: 0 -75px;
      }
    }
    .col-md-8 {
      margin: 0 auto;
      display: table;
      float: none;
    }
  }
  &.block-quote {
    padding: 90px 0;
    background: $body;
    text-align: center;
    background-size: cover !important;
    background-position: 50% 50%;
    @include breakpoint($bp-charlie) {
      padding: 40px 0;
      min-height: 630px;
      .container {
        height: auto;
        position: absolute;
      }
    }
    .col-sm-9 {
      margin: 0 auto;
      display: table;
      float: none;
    }
    p {
      color: #fff;
      font-size: 22px;
      line-height: 145%;
      font-family: $primary-font;
      font-weight: 400;
      font-style: italic;
      margin-bottom: 25px;
      @include breakpoint($bp-charlie) {
        font-size: 25px;
      }
      @include breakpoint($bp-bobby) {
        font-size: 28px;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
    h6 {
      margin-top: 35px;
      margin-bottom: 0;
      color: #fff;
    }
  }
  &.buttons {
    .all-buttons {
      float: left;
      width: 100%;
      text-align: center;
      @include breakpoint($bp-charlie) {
        float: none;
        margin: 0 auto;
        display: table;
        width: auto;
      }
      .btn {
        margin: 10px 0;
        display: inline-block;
        float: none;
        @include breakpoint($bp-charlie) {
          margin: 10px;
        }
      }
    }
    .btn {
      float: none;
      width: 100%;
      display: table;
      margin: 0 auto 8px auto;
      @include breakpoint($bp-charlie) {
        margin: 0 0 0 10px;
        width: auto;
        display: block;
        float: left;
      }
    }
  }
  &.signup {
    @include breakpoint($bp-delta) {
      padding: 200px 0;
    }
    input[type='text'],
    input[type='email'] {
      color: #333;
    }
    p {
      text-align: center;
      @include breakpoint($bp-delta) {
        width: 70%;
        margin: 0 auto;
        display: table;
      }
    }
    h6 {
      text-align: center;
    }
  }
  &.image-slider {
    width: 100%;
    position: relative;
    padding: 0;
    overflow: hidden;
    margin-bottom: 25px;
    @include breakpoint($bp-charlie) {
      margin-bottom: 50px;
      padding: 0;
    }
    .full-widht-slider-image {
      height: 300px;
      width: 100%;
      float: left;
      outline: none;
      background-repeat: no-repeat;
      background-position: center 0;
      background-size: cover !important;
      @include breakpoint($bp-charlie) {
        height: 450px;
      }
      @include breakpoint($bp-delta) {
        height: 650px;
      }
    }
    .slick-prev {
      position: absolute;
      top: 46%;
      @include transform(translateY(-50%));
      left: 25px;
      width: 25px;
      height: 25px;
      -webkit-mask: url('img/arrow.svg') no-repeat 100% 100%;
      mask: url('img/arrow.svg') no-repeat 100% 100%;
      -webkit-mask-size: cover;
      mask-size: cover;
      background-color: #fff;
      @include hover-fade();
      text-indent: -9999px;
      cursor: pointer;
      @include transform(rotate(180deg));
      z-index: 25;
      opacity: 0.5;
      @include breakpoint($bp-charlie) {
        width: 45px;
        height: 45px;
      }
      &:hover {
        background: #fff;
        opacity: 1;
      }
    }
    .slick-next {
      position: absolute;
      top: 50%;
      @include transform(translateY(-50%));
      right: 25px;
      width: 25px;
      z-index: 25;
      height: 25px;
      -webkit-mask: url('img/arrow.svg') no-repeat 100% 100%;
      mask: url('img/arrow.svg') no-repeat 100% 100%;
      -webkit-mask-size: cover;
      mask-size: cover;
      background-color: #fff;
      @include hover-fade();
      text-indent: -9999px;
      opacity: 0.5;
      cursor: pointer;
      @include breakpoint($bp-charlie) {
        width: 45px;
        height: 45px;
      }
      &:hover {
        background: #fff;
        opacity: 1;
      }
    }
  }
}

//control the initialization behavior of sliders
.js .image-slider .featured-image {
  display: none;
}
.js .image-slider .featured-image:first-child {
  display: block;
}
.js .slick-initialized.image-slider .featured-image {
  display: block;
}
.no-js {
  .image-slider .featured-image + .featured-image {
    margin-top: 40px;
  }
}

.donation {
  ul {
    margin: 0px;
    padding: 0px;
    float: left;
    width: 100%;
    li {
      float: left;
      width: 100%;
      list-style-type: none;
      margin: 0 0 5px;
      @include breakpoint($bp-charlie) {
        width: 48%;
        margin: 0 0 10px;
        &:nth-child(2n + 2) {
          float: right;
        }
      }
      a {
        color: #fff;
        text-align: center;
        text-decoration: none;
        display: block;
        float: left;
        width: 100%;
        font-size: 18px;
        padding: 8px 5px 10px;
        background: $secondary;
        font-family: $headline-font;
        font-weight: 600;
        border: 2px solid $secondary;
        border-radius: $borderRadius;
        line-height: 30px;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
        &:hover {
          background: darken($secondary, 10%);
          border: 2px solid darken($secondary, 10%);
        }
        @include breakpoint($bp-charlie) {
          font-size: 21px;
        }
      }
      &:last-child {
        a {
          background: transparent;
          border: 2px solid #fff;
          font-size: 14px;
          padding: 7px 5px;
          @include breakpoint($bp-charlie) {
            height: 51px;
          }
          &:hover {
            background: darken($secondary, 10%);
            border: 2px solid darken($secondary, 10%);
          }
        }
      }
    }
  }
  .all-columns {
    margin-top: 10px;
    .text-col {
      &:last-child {
        padding: 0px;
        margin-top: 10px;
        @include breakpoint($bp-charlie) {
          margin: 0px;
        }
      }
    }
  }
  .col-lg-10,
  .col-lg-8 {
    float: none;
    margin: 0 auto;
    display: table;
  }
  .donation-title {
    float: left;
    width: 100%;
    text-align: center;
    color: #fff;
    margin-bottom: 30px;
    @include breakpoint($bp-charlie) {
      width: 100%;
    }
    h6 {
      margin-bottom: 15px;
      color: #fff;
    }
    h2 {
      margin-bottom: 25px;
      color: #fff;
    }
  }
}

.quick-link__flex {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid $details;
  border-bottom: 1px solid $details;

  @include breakpoint($bp-charlie) {
    text-align: left;
    flex-direction: row;
  }
}

.quick-link__headline,
.quick-link__link {
  margin: 0;

  @include breakpoint($bp-charlie) {
    max-width: 50%;
  }
}

.quick-link__headline {
  text-transform: uppercase;
  font-size: 12px;
}

.quick-link__link {
  font-size: 16px;
  padding-left: 10px;

  svg {
    @include transform(rotate(-90deg) translateX(1px));
    width: 12px;
    height: 6px;
    margin-left: 8px;
  }
}

.module__mobile-image {
  background-color: $solidBgColor;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @include breakpoint($bp-charlie) {
    display: none;
  }

  &.-color + .overlay {
    display: none;

    @include breakpoint($bp-charlie) {
      display: block;
    }
  }
}
