/* Enter Your Custom CSS Here */

@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,700;1,400;1,700&display=swap');

/* hide heart and menu */

.heart {
    display: none !important;
}

nav.primary ul li {
    float: left;
    padding-right: 20px;
    @include breakpoint(920px) {
        padding-right: 0;
        padding-left: 20px;
    }
}

@media (max-width: 768px) {
.main-header .control {
    float: right;
    width: 100%;
    margin-top: 0;
}
}

/* logo and modal */
.main-logo .st0 {
    fill:#212121 !important;
}
.pop-over.video {
    top: 0;
    left: 0;
	    background: rgba(11,11,11,.95);
}
.pop-over.video p {
    color:#fff;
}
button.mfp-close {
    position: fixed;
    top: 40px !important;
    right: 40px !important;
		transform: initial !important;
}

/* sticky bar on right basic style */
.float-link {
		background:#e49b30 !important;
		color:#212121 !important;
}
.float-link:hover {
	background:#f95f2f !important;
	color:#212121 !important;
}
.float-link a:hover {
	background:none !important;
}
.float-link .wrap-text span, .float-link .arrow svg {
		color:#212121;
	fill:#212121 !important;
	font-family: 'Rubik', sans-serif;
	font-size:20px;
}
.float-link:hover .wrap-text span, .float-link:hover .arrow svg {
		color:#212121;
	fill:#212121 !important;
}
.close-page a:hover svg {
	color:#000 !important;
}

/* footer */

.main-site-footer {
    background: #121212;
}
.footer-meta .disclaimer, .footer-meta .disclaimer p {
		text-align:left;
    color: #fff;
}

.footer-meta .disclaimer div {
	text-align:center;
}

@media (min-width: 1040px) {
#main-site-footer .container {
    max-width: 100%;
    margin: 0 auto;
}
}

@media (max-width: 768px) {
#main-site-footer iframe {
    width: 100%;
    height: 270px;
    margin: 40px 0;
}
}

.logos {
    display: block;
    clear: both;
    width: 100%;
    float: left;
    padding-bottom: 30px;
}

.logos img {
    float: left;
	  margin-right: 20px;
}

/* hide download */

a.download {
    display: none !important;
}
.home-feed .grid .col-single .home-thumbnail:hover .shadow-overlay:before {
    opacity: 1;
	background: linear-gradient(to bottom,transparent 0,transparent 0%,#000 100%);
}
a:hover.full-link::before {
    content: "Read the Story >";
    position: absolute; 
    font-weight: bold;
    text-align: center;
    width: 100%;
    top: 68%;
    z-index: 999;
    cursor:pointer !important;
    color:#e49b30 !important;
    transition-duration: .5s;
    .col-single--empty & {
        display: none;
    }
    .col-single--video & {
        content: "Watch the Video >";
    }
}

/* everything else */

.heart .heart-this-wrap .heart-this span {
    font-weight: 400;
    font-size: 14px;
}

.single-poster .post-info .info .description p {
    font-size: 14px;
    line-height: 150%;
}

.home-introduction a, .footer-meta a {
    color: #346fcc !important;
    font-weight: 500;
    border-bottom: 2px solid #e49b30;
		text-decoration:none;
}

.home-feed .grid .col-single .home-thumbnail {
    height: inherit !important;
}

body, nav.primary ul li, .footer-meta span, p, .single-poster .post-info .info [data-widget-host=we-mobilize] .wem__event-detail__title, .single-poster .post-info .info [data-widget-host=we-mobilize] .wem__events-panel__headline, .single-poster .post-info .info h3, [data-widget-host=we-mobilize] .single-poster .post-info .info .wem__event-detail__title, [data-widget-host=we-mobilize] .single-poster .post-info .info .wem__events-panel__headline, .home-feed .grid .col-single .info .name p, .single-poster .post-info .info .description p {
	font-family: 'Rubik', sans-serif;
}
p {
	line-height:160%;
}
.single-poster .post-info .info h3 {
	font-weight:700;
	font-size:23px;
}
a, .home-introduction a {
	color:#000;
}
::selection {
	background:#e49b30;
	color:#000 !important;
}
p a:hover, nav.primary ul li a:hover, .footer-meta a:hover {
	background:#e49b30;
	color:#000 !important;
}
.single-poster .post-info .share a:hover, .main-header .social.links li a:hover {
    color: #f95f2f;
}
.home-introduction p {
    margin-bottom: 10px;
}

.close-page a:hover {
    background: #fff !important; 
}
.close-page a:hover svg, .close-page a:hover svg g {
	fill: #212121 !important;
	color: #212121 !important;
}

.main-header .logo img, .main-header .logo svg {
    display: inline-block;
    width: calc(440px * 0.8);
    height: calc(55px * 0.8);
    margin: -10px 0px 10px 0px;
    transition: .35s;
    max-height: 55px;
    @include breakpoint(920px) {
        width: 440px;
    height: 55px;
    }
}
.logo a {color:#212121;}

.single-poster .close-page a:hover svg .st0, .single-poster .close-page a:hover svg .st1 {
    fill: #212121!important;
	color:#212121!important;
}
h1, h2, h3, h4 {
    font-family: neusa-next-std-compact, sans-serif !important;
    text-transform: uppercase;
    letter-spacing: 0em;
    font-weight: 700 !important;
}
h5, h6 {
    font-family: Rubik,sans-serif !important;
    text-transform: uppercase;
    letter-spacing: 0em;
    font-weight: 800 !important;
}



.footer-meta span.site-by {
	display:none; 
}
.single-poster .post-info .poster img {
    border: 1px solid rgba(0,0,0,.2);
}
.home-feed .grid .col-single {
    position: relative;
}
.home-feed .grid .col-single .info .name p {
    font-size: 12px !important;
}

a.external-url {
    display:block;
    background: #212121;
    height: 30px;
    width: 90px;
    text-align: center;
    color: #e49b30;
    font-size: 12px;
    font-weight: bold;
    border-radius: 3px;
}
.home a.external-url {
    position: absolute;
    right: 0;
    bottom: -15px;
    @media (min-width: 768px) {
        bottom: 0px;
    }
}
a:hover.external-url {
    background: #e49b30;
    color: #212121;
}
.single a.external-url {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    line-height: 2.75em;
    font-size: 18px;
    margin-top: 35px;
}


@media (min-width: 768px) {
.float-link {
		top: 55px;
    height: 285px !important;
		background:#e49b30;
    width: 45px;
}
	.float-link .wrap-text {
    left: 10px;
	}
p {
	font-size:16px;
}
	.container {
    width: 850px;
	}
}

@media (min-width: 1600px) {
	.home-feed .container {
			max-width: 2200px;
		    padding: 0 60px;
	}
	.main-header .logo img, .main-header .logo svg {
    width: 600px;
    height: 84px;
    margin: 3px 0 25px 0;
	}
}
@media (min-width: 1250px) {
	.pusher {
    height: 15px;
	}
	.main-header .logo img, .main-header .logo svg {
    display: inline-block;
    width: 550px;
    height: 84px;
    margin: -25px 0px 20px 0px;
    transition: .35s;
    max-height: 84px;
	}
}

@media (min-width: 1040px) {
	.home-feed .grid .col-single {
		width: 21%;
    margin: 0 2% 5%;
	}
	.float-link {
	  top: 0;
		height: 100% !important;
	}
	.container {
    width: 1100px;
	}
	.float-link .wrap-text span.text div.desktop {
		display:block;
	}
	.float-link .wrap-text span.text div.mobile {
		display:none;
	}
}

@media (max-width: 1039px) {
	.float-link .wrap-text span.text div.desktop {
		display:none;
	}
	.float-link .wrap-text span.text div.mobile {
		display:block;
	}
}

@media (max-width: 768px) {
	.main-header .logo img, .main-header .logo svg {
    display: inline-block;
    width: 280px;
    max-height: 55px;
	}
	.home-feed .grid .col-single {
    margin-bottom: 50px;
	}
	.home a.external-url {
    line-height: 2.9em;
    width: 100%;
    bottom: -30px;
    height: 36px;
	}
}
