/* Variables are only for the topper menus */

$solidBgColor: #fff;
$headlineColor: #fff;
$paragraphColor: #fff;

// Tall Topper Height
$tallDesktop: 780px;
$tallTablet: 540px;

//Short Topper Height
$shortDesktop: 420px;
$shortTablet: 420px;

.home .topper {
  height: auto !important;
  max-height: 100%;
  min-height: 150px;
  padding: 0 0 0 0;
  @include transition(all 0.3s ease-in-out);
  //margin-top: $header-mobile-height;
  @include breakpoint($bp-charlie) {
    height: calc(100vh - 72px) !important;
    max-height: 650px;
    min-height: 550px;
    //margin-top: $header-height;
  }
  @include breakpoint($bp-bobby) {
    height: calc(100vh - 72px) !important;
    max-height: 850px;
    min-height: 720px;
    //margin-top: $header-height;
  }
}

.topper__mobile-image {
  background-color: $solidBgColor;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  @include breakpoint($bp-charlie) {
    display: none;
  }
}

.topper {
  background-color: $solidBgColor;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  float: left;
  width: 100%;
  position: relative;
  padding: 80px 0 0;
  //margin-top: $header-mobile-height;
  @include breakpoint($bp-charlie) {
    padding: 130px 0 0 0;
    //margin-top: $header-height;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #212121;
    text-align: left;
  }
  h6 {
    margin-bottom: 10px;
  }
  h1 {
    margin-bottom: 0;
    line-height: 1;
  }
  .close-page {
    position: absolute;
    top: 18px;
    right: 18px;
    height: 32px;
    width: 32px;
    @include breakpoint($bp-charlie) {
      top: 68px;
      right: 65px;
      height: 41px;
      width: 41px;
    }
    a {
      svg {
        width: 32px;
        height: 32px;
        @include breakpoint($bp-charlie) {
          width: 41px;
          height: 41px;
        }
        .st1 {
          fill: #c7c7c7;
          @include hover-fade();
        }
        .st0 {
          fill: #c7c7c7;
          @include hover-fade();
        }
      }
      &:hover {
        svg {
          .st1,
          .st0 {
            fill: $secondary !important;
          }
        }
      }
    }
  }
  .page-sub-title {
    text-transform: none;
    font-family: $primary-font!important;
  }
  p {
    color: $paragraphColor;
  }
  .topper__mobile-image.-color + .overlay {
    display: none;
    @include breakpoint($bp-charlie) {
      display: block;
    }
  }
  .col-md-8,
  .col-md-10 {
    margin: 0 auto;
    float: none;
  }
  .container {
    height: 100%;
    max-width: 750px;
    max-width: 100%;
    // set to 0 so it kicks in with animations. if animation is removed, remove this opacity
    
    .js & {
      opacity: 1;
    }
    .row {
      height: 100%;
    }
    .col-md-8 {
      margin: 0 auto;
      float: none;
    }
  }
  .donation {
    margin: 15px 0 0 0;
    @include breakpoint($bp-charlie) {
      margin: 20px 0 0 0;
    }
    .donation-note {
      color: white;
    }
    .col-lg-8 {
      margin: 0 auto;
      float: none;
    }
    ul {
      li {
        font-weight: 700;
      }
    }
  }
  .deck {
    margin-bottom: 25px;
    @include breakpoint($bp-charlie) {
      margin-bottom: 45px;
    }
    p {
      margin-bottom: 20px;
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  &.short {
    height: auto;
    padding: 65px 0 55px 0;
    .deck {
      margin-bottom: 0px;
    }
    @include breakpoint($bp-charlie) {
      height: $shortTablet;
    }
    @include breakpoint($bp-bobby) {
      height: $shortDesktop;
    }
  }
  &.tall {
    height: auto;
    padding: 85px 0 100px 0;
    @include breakpoint($bp-charlie) {
      height: $tallTablet;
    }
    @include breakpoint($bp-bobby) {
      height: calc(100vh - 74px);
      min-height: 600px;
    }
    .deck {
      display: none;
      @include breakpoint($bp-charlie) {
        display: block;
      }
    }
  }
  &.home {
    height: auto;
    padding: 55px 0 105px;
    @include breakpoint($bp-charlie) {
      height: $tallTablet;
      padding: 0 0 0 0;
    }
    @include breakpoint($bp-bobby) {
      height: $tallDesktop;
      padding: 0 0 0 0;
    }
    .deck {
      display: block;
      @include breakpoint($bp-charlie) {
        display: block;
      }
    }
  }
  .read-more {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    max-width: 47px;
    @include breakpoint($bp-bobby) {
      max-width: 60px;
      bottom: 35px;
    }
    a {
      padding: 10px;
      float: left;
      width: 100%;
      height: auto;
      color: #fff;
      svg {
        position: relative;
        width: 100%;
        @include hover-fade();
      }
      &:hover {
        color: $secondary;
        svg {
          @include transform(scale(1.15));
        }
      }
    }
  }
}

.topper-posts {
  padding: 70px 0 20px;
  @include breakpoint($bp-charlie) {
    padding: 100px 0 0 0;
  }
  @include breakpoint($bp-bobby) {
    padding: 140px 0 40px;
  }
  &.simple-topper {
    .col-md-8 {
      display: table;
      margin: 0 auto 0 auto;
    }
    h6,
    h1 {
      text-align: center;
    }
  }
  .page-meta {
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    margin-bottom: 15px;
    span {
      padding: 0 10px;
      font-size: 20px;
      font-family: $headline-font;
      font-weight: 600;
      color: $body;
    }
    a {
      color: $body;
      &:hover {
        color: $secondary;
      }
    }
  }
  h1,
  h2 {
    margin-bottom: 0px;
  }
  h2 {
    text-align: center;
    max-width: 700px;
  }
  .post-info {
    text-align: center;
    font-size: 18px;
    margin-top: 15px;
  }
  .sub-nav {
    @include breakpoint($bp-delta) {
      margin-bottom: 25px;
    }
  }
}

// Topper Call To Action Area

.topper-cta {
  float: left;
  width: 100%;
  .btn {
    margin: 0 auto;
    display: table;
  }
  .play-row {
    width: 100%;
    height: 100px;
    margin-top: 40px;
    .play-button {
      position: relative;
      margin: 0 auto;
      display: table;
      float: none;
      left: auto;
      top: 0;
      cursor: pointer;
      width: 60px;
      height: 60px;
      transition: 0.3s;
      .play-bg {
        background: #fff;
        width: 25px;
        height: 25px;
        top: 18px;
        left: 20px;
        position: absolute;
        transition: 0.3s;
      }
      svg {
        z-index: 9;
        position: relative;
      }
      @include breakpoint($bp-charlie) {
        margin: 10px auto 0;
        top: 0;
        width: 90px;
        height: 90px;
        .play-bg {
          width: 40px;
          height: 40px;
          top: 23px;
          left: 28px;
        }
      }
    }
  }
  .video-link {
    position: relative;
  }
  .video-popup {
    width: 75px;
    height: 75px;
    background: $primary;
    @include box-shadow(
      $boxShadowTop,
      $boxShadowLeft,
      $boxShadowBlur,
      $boxShadowColor
    );
    border-radius: 100%;
    display: table;
    margin: 0 auto;
    position: relative;
    outline: none;
    @include hover-fade();
    &:hover {
      background: $secondary;
    }
    span {
      width: 30px;
      height: 35px;
      position: absolute;
      left: 0;
      right: 0;
      top: 22px;
      margin: 0 auto;
      svg {
        margin-left: 5px;
        width: 100%;
        height: auto;
      }
    }
  }
  .topper-form {
    margin: 0 auto;
    display: block;
    float: none;
    width: 100%;
    padding: 0;
    @include breakpoint($bp-adams) {
      width: 300px;
    }
    form {
      .first-name,
      .email {
        float: left;
        width: 100%;
        margin-bottom: 4px;
      }
      .zip {
        float: left;
        width: 100%;
        margin-bottom: 13px;
      }
      .submit {
        width: 100%;
        float: left;
        margin-bottom: 0;
      }
    }
  }
}

// Topper Video Background

.topper-video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
  background-size: cover !important;
}

.fullscreen-bg__video {
  display: none;
  @include breakpoint($bp-charlie) {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media (min-aspect-ratio: 16/9) {
  .fullscreen-bg__video {
    height: 300%;
    top: -100%;
  }
}

@media (max-aspect-ratio: 16/9) {
  .fullscreen-bg__video {
    width: 300%;
    left: -100%;
  }
}

// Topper Menu

.blog .sub-nav,
.archive .sub-nav,
.category .sub-nav {
  background: #eee;
}

.sub-nav {
  float: left;
  width: 100%;
  padding: 0 20px;
  @include breakpoint($bp-bobby) {
    padding: 0 40px;
  }
  .container {
    padding: 0;
  }
  .social {
    margin: 0;
    float: right;
    display: none;
    height: 70px;
    background: #eeeeee;
    padding: 21px 30px;
    @include breakpoint($bp-bobby) {
      display: block;
    }
    span {
      margin: 0 10px 0;
      font-family: $headline-font;
      color: #666666;
      text-transform: none;
      font-weight: 600;
      font-size: 14px;
    }
    ul {
      li {
        a {
          padding: 0px 8px;
          color: #666666;
          &:hover {
            color: $secondary;
          }
        }
      }
    }
  }
  .topper-menu {
    float: left;
    width: 100%;
    display: block;
    overflow: hidden;
    @include breakpoint($bp-charlie) {
      display: table;
      margin: 0 auto;
      width: auto;
      float: none;
    }
    @include breakpoint($bp-bobby) {
      display: block;
      width: auto;
      float: none;
      margin: 0 auto;
      display: table;
    }
    &.news {
      display: table;
      margin: 0 auto;
      width: auto;
      float: none;
    }
  }
  nav {
    margin-top: 15px;
    margin-bottom: 15px;
    overflow: hidden;
    @include breakpoint($bp-charlie) {
      margin-top: 0;
      margin-bottom: 22px;
    }
    ul {
      margin: 0px;
      padding: 0px;
      li {
        float: left;
        width: 100%;
        @include breakpoint($bp-charlie) {
          width: auto;
        }
        &.current-menu-item {
          a {
            color: #999999;
            &:after {
              @include breakpoint($bp-charlie) {
                background: #999999;
                opacity: 1;
              }
            }
          }
        }
        &:first-of-type {
          a {
            @include breakpoint($bp-charlie) {
              margin-left: 0 !important;
            }
          }
        }
        &:last-of-type {
          a {
            @include breakpoint($bp-charlie) {
              margin-right: 0 !important;
            }
          }
        }
        .sub-menu {
          display: none;
        }
        a {
          font-size: 16px;
          font-weight: 600;
          font-family: $headline-font;
          display: block;
          text-align: center;
          color: $body;
          position: relative;
          margin: 5px 10px;
          padding: 5px;
          @include breakpoint($bp-charlie) {
            padding: 23px 0 5px 0;
            margin: 0 20px;
          }
          &:after {
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            bottom: 0;
            left: 0;
            background: $body;
            opacity: 0;
            @include hover-fade();
          }
          &:hover {
            &:after {
              @include breakpoint($bp-charlie) {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
}
