.home-introduction {
  margin-bottom: 45px;
  @include breakpoint($bp-charlie) {
    margin-bottom: 75px;
  }
  p {
    margin-bottom: 0;
    color: #181616;
  }
}

.home-feed {
  .container {
    max-width: 1250px;
    width: 100%;

    @include breakpoint(1300px) {
      max-width: 1500px;
    }
  }
  .grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    .col-single {
      width: 100%;
      display: inline-block;
      margin-bottom: 25px;
      opacity: 0;
      transform: translate3d(0, -10px, 0);
      &.no-js {
        opacity: 1;
        transform: none;
      }
      @include breakpoint($bp-bravo) {
        width: 46%;
        margin: 0 2% 2%;
      }
      @include breakpoint($bp-charlie) {
        width: 29%;
        margin: 0 2% 2%;
      }
      @include breakpoint($bp-delta) {
        width: 23%;
        margin: 0 1% 5%;
      }
      &__fig {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        width: 100%;
        height: 100%;
        video {
          position: absolute;
          height: 100%;
          top: 0%;
          left: 50%;
          transform: translateX(-50%);
        }
      }
      .home-thumbnail {
        position: relative;
        width: 100%;
        padding-bottom: 151%;
        background-position: center center;
        background-size: cover !important;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
        transform: translateY(0px);
        transition: transform 0.45s, box-shadow 0.3s ease-in-out;
        @include breakpoint($bp-bravo) {
          height: 395px;
        }
        @include breakpoint($bp-charlie) {
          height: 395px;
        }
        @include breakpoint(1300px) {
          height: 485px;
        }
        .shadow-overlay {
          position: absolute;
          width: 100%;
          height: 75%;
          left: 0;
          bottom: 0;
          overflow: hidden;
          z-index: 2;
          &:before {
            width: 100%;
            height: 100%;
            position: absolute;
            content: '';
            transition: opacity 0.5s, bottom 0.5s ease-in-out;
            left: 0;
            bottom: -90px;
            opacity: 0;
            background: -moz-linear-gradient(
              top,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0) 50%,
              rgba(0, 0, 0, 1) 100%
            );
            background: -webkit-linear-gradient(
              top,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0) 50%,
              rgba(0, 0, 0, 1) 100%
            );
            background: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0) 0%,
              rgba(0, 0, 0, 0) 50%,
              rgba(0, 0, 0, 1) 100%
            );
          }
        }
        .full-link {
          z-index: 3;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
        }
        .social-media-overlay {
          padding: 0;
          position: absolute;
          width: 100%;
          bottom: 30px;
          left: 0;
          z-index: 5;
          text-align: center;
          a {
            position: relative;
            display: inline-block;
            margin: 0 15px;
            color: #fff;
            font-size: 38px;
            opacity: 0;
            bottom: -10px;
            transition: all 0.3s;
            transition-delay: 0;
            i.fa-twitter {
              font-size: 41px;
            }
            svg {
              width: 35px;
              height: 35px;
              position: relative;
              bottom: 6px;
            }
            &:hover {
              color: $primary;
            }
          }
        }
        &:hover {
          .shadow-overlay {
            &:before {
              bottom: 0;
              opacity: 0.6;
            }
          }
          .social-media-overlay {
            a {
              bottom: 0;
              opacity: 1;
              transform-origin: center;
              transform: scale3d(1, 1, 1);
              &:hover {
                color: rgba(#fff, 0.7);
                transform: scale3d(1.1, 1.1, 1);
              }
            }
          }
          transform: translateY(-5px);
          box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.25);
        }
      }
      .info {
        margin-top: 15px;
        .heart {
          float: right;
          .heart-this-wrap {
            width: 33px;
          }
        }
        .name {
          float: left;
          width: 75%;
          p {
            margin: 0;
            padding: 0;
            font-family: $primary-font;
            font-weight: 700;
            color: #000000;
            font-size: 14px;
            line-height: 1.2em;
          }

          a {
            color: inherit;

            &:hover {
              color: $secondary;
            }
          }
        }
      }
    }
  }
}

@keyframes bang {
  from {
    @include transform(translate3d(0, 0, 0));
    opacity: 1;
  }
}

.heart {
  .heart-this-wrap {
    position: relative;
    width: 55px;
    .heart-this {
      position: relative;
      width: 100%;
      color: #989e9e;
      i {
        position: absolute;
        display: block;
        left: 20%;
        top: 18px;
        width: 4px;
        height: 7px;
        background: $primary;
        opacity: 0;
      }
      &.is-animating {
        @for $i from 1 through 50 {
          i:nth-of-type(#{$i}) {
            @include transform(
              translate3d(random(200) - 80 + px, random(35) - 100 + px, 0)
                rotate(random(360) + deg)
            );
            background: $primary;
            //@include animation(bang 400ms ease-out forwards);
            animation-name: bang;
            animation-timing-function: ease-out;
            animation-duration: 650ms;
            animation-direction: forwards;
            opacity: 0;
          }
          i:nth-of-type(odd) {
            background: $secondary;
          }
        }
      }
      span {
        font-weight: 700;
        font-size: 16px;
        color: #000000 !important;
        float: left;
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        background-image: url('img/heart.svg');
        background-size: 80% !important;
        -webkit-background-size: 80%;
        width: 23px;
        height: 19px;
        margin-left: 10px;
        @include transition(all 0.05s ease-in);
      }
      &:hover {
        &:before {
          background-position: left !important;
          @include transform(scale(1.15));
        }
      }
      &.is-animating {
        background-position: left !important;
      }
      &.active {
        &:before {
          background-position: left !important;
          background-image: url('img/heart-active.svg');
        }
      }
    }
  }
  &.white {
    .heart-this-wrap {
      .heart-this {
        color: #fff;
        span {
          color: #fff;
        }
        &:before {
          background-image: url('img/heart-white.svg');
        }
        &.active {
          &:before {
            background-position: left !important;
            background-image: url('img/heart-active.svg');
          }
        }
      }
    }
  }
}
