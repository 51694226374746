.main-site-footer {
  float: left;
  width: 100%;
  padding: 20px 0 80px;
  color: $body;
  background: #fff;
  @include breakpoint($bp-charlie) {
    padding: 40px 0;
  }
  a {
    color: #666666;
    text-decoration: underline;
    &:hover {
      color: $secondary;
    }
  }
}

.footer-meta {
  float: left;
  width: 100%;
  .disclaimer {
    margin: 0 0 25px 0;
    text-align: center;
    float: left;
    width: 100%;
    a {
      white-space: nowrap;
    }
    span {
      margin: 0;
    }
  }
  span {
    color: #666666;
    font-family: $headline-font;
    font-weight: 400;
    float: left;
    width: 100%;
    display: block;
    font-size: 14px;
    line-height: 115%;
    &.campaign-name {
      margin-top: 14px;
    }
    &.site-by {
      text-align: center;
      font-weight: 400;
      color: #666666;
      .heart {
        font-style: normal !important;
        font-size: 15px;
        @include hover-fade();
      }
      .link {
        text-decoration: underline;
        font-style: normal;
        @include hover-fade();
      }
      a {
        text-decoration: none;
        &:hover {
          color: #666;
          .heart,
          .link {
            color: #381c75;
          }
        }
      }
    }
  }
}
