// Variables. Needed for style-guide.

// H1 Super
$h1super: 64px;
$h1superTablet: 54px;
$h1superMobile: 36px;
$h1superWeight: 600;
$h1superFont: $headline-font;
// H1
$h1: 54px;
$h1Tablet: 44px;
$h1Mobile: 36px;
$h1Weight: 600;
$h1Font: $headline-font;
// H2
$h2: 44px;
$h2Tablet: 32px;
$h2Mobile: 24px;
$h2Weight: 600;
$h2Font: $headline-font;
// H3
$h3: 32px;
$h3Tablet: 27px;
$h3Mobile: 20px;
$h3Weight: 600;
$h3Font: $headline-font;
// H4
$h4: 27px;
$h4Tablet: 23px;
$h4Mobile: 18px;
$h4Weight: 600;
$h4Font: $headline-font;
// H5
$h5: 23px;
$h5Tablet: 20px;
$h5Mobile: 16px;
$h5Weight: 600;
$h5Font: $headline-font;
// H6
$h6: 20px;
$h6Tablet: 18px;
$h6Mobile: 16px;
$h6Weight: 600;
$h6Font: $headline-font;
//Body
$p: 19px;
$pTablet: 18px;
$pMobile: 16px;
$pWeight: 400;
$pFont: $primary-font;

h1,
.h1 {
  font-family: $h1superFont;
  color: $headlineColor;
  font-weight: $h1superWeight;
  font-size: $h1Mobile; // mobile size
  line-height: 116%;
  margin: 0 0 0.5em;
  @include breakpoint($bp-charlie) {
    font-size: $h1Tablet; // tablet size
  }
  @include breakpoint($bp-bobby) {
    font-size: $h1; //desktop size
  }
  &.super {
    font-size: $h1superMobile;
    @include breakpoint($bp-charlie) {
      font-size: $h1superTablet;
    }
    @include breakpoint($bp-bobby) {
      font-size: $h1super;
    }
  }
  i,
  em,
  strong {
    font-weight: 600 !important;
    font-style: normal !important;
  }
}

h2,
.h2 {
  font-family: $h2Font;
  color: $headlineColor;
  font-weight: $h2Weight;
  font-size: $h2Mobile;
  line-height: 120%;
  margin: 0 0 0.5em;
  @include breakpoint($bp-charlie) {
    font-size: $h2Tablet;
  }
  @include breakpoint($bp-bobby) {
    font-size: $h2;
  }
  i,
  em,
  strong {
    font-weight: $h2Weight !important;
    font-style: normal !important;
  }
}

h3,
.h3 {
  font-family: $h3Font;
  color: $headlineColor;
  font-weight: $h3Weight;
  font-size: $h3Mobile;
  line-height: 125%;
  margin: 0 0 0.5em;
  @include breakpoint($bp-charlie) {
    font-size: $h3Tablet;
  }
  @include breakpoint($bp-bobby) {
    font-size: $h3;
  }
  i,
  em,
  strong {
    font-weight: $h3Weight !important;
    font-style: normal !important;
  }
}

h4,
.h4 {
  font-family: $h4Font;
  color: $headlineColor;
  font-weight: $h4Weight;
  font-size: $h4Mobile;
  line-height: 130%;
  margin: 0 0 0.5em;
  @include breakpoint($bp-charlie) {
    font-size: $h4Tablet;
  }
  @include breakpoint($bp-bobby) {
    font-size: $h4;
  }
  i,
  em,
  strong {
    font-weight: $h4Weight !important;
    font-style: normal !important;
  }
}

h5,
.h5 {
  font-family: $h5Font;
  color: $headlineColor;
  font-weight: $h5Weight;
  font-size: $h5Mobile;
  line-height: 130%;
  margin: 0 0 0.5em;
  @include breakpoint($bp-charlie) {
    font-size: $h5Tablet;
  }
  @include breakpoint($bp-bobby) {
    font-size: $h5;
  }
  i,
  em,
  strong {
    font-weight: $h5Weight !important;
    font-style: normal !important;
  }
}

h6,
.h6 {
  font-family: $h6Font;
  color: $headlineColor;
  font-weight: $h6Weight;
  font-size: $h6Mobile;
  line-height: 130%;
  margin: 0 0 0.5em;
  @include breakpoint($bp-charlie) {
    font-size: $h6Tablet;
  }
  @include breakpoint($bp-bobby) {
    font-size: $h6;
  }
  i,
  em,
  strong {
    font-weight: $h6Weight !important;
    font-style: normal !important;
  }
}

p {
  font-family: $primary-font;
  font-weight: $pWeight;
  font-size: $pMobile;
  line-height: 155%;
  margin: 0 0 20px;
  letter-spacing: 0;
  color: $body;
  //-webkit-font-smoothing: auto;// would be more readable and performant....

  &.caption {
    font-size: 14px;
    @include breakpoint($bp-charlie) {
      font-size: $pMobile;
    }
  }
  &.small {
    font-family: $headline-font;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
  }
  &.teaser {
    font-size: 14px;
    @include breakpoint($bp-charlie) {
      font-size: 16px;
    }
    @include breakpoint($bp-charlie) {
      font-size: $pTablet;
    }
  }
  @include breakpoint($bp-charlie) {
    font-size: $pTablet;
    margin: 0 0 25px;
  }
  @include breakpoint($bp-bobby) {
    font-size: $p;
    margin: 0 0 25px;
  }
}

body {
  font-family: $pFont;
  font-weight: $pWeight;
  font-size: $pMobile;
  line-height: 150%;
  letter-spacing: 0;
  color: $body;
  @include breakpoint($bp-charlie) {
    font-size: $pTablet;
  }
  @include breakpoint($bp-bobby) {
    font-size: $p;
  }
}

.content ul,
.content ol {
  font-family: $primary-font;
  margin-left: 15px;
  padding-left: 15px;
  font-size: $pMobile;
  line-height: 150%;
  margin-bottom: 40px;
  font-family: $primary-font;
  font-weight: $pWeight;
  color: $body;
  @include breakpoint($bp-charlie) {
    font-size: $pTablet;
    line-height: 30px;
    margin-bottom: 30px;
    margin-left: 25px;
    padding-left: 25px;
  }
  @include breakpoint($bp-bobby) {
    font-size: $p;
  }
  ul,
  ol {
    margin: 5px;
  }
  ul li {
    list-style: disc;
  }
}

small {
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  text-transform: uppercase;
  color: $body;
  font-family: $headline-font;
}
