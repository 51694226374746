$buttonBorderRadius: max(
  $borderRadius - 1px,
  0px
); //not sure why button is 1px less than modules but it is atm

.btn {
  background: #212121;
  border-radius: $buttonBorderRadius;
  @include box-shadow(
    $boxShadowTop,
    $boxShadowLeft,
    $boxShadowBlur,
    $boxShadowColor
  );
  color: $primary;
  cursor: pointer;
  display: block; // should be inline-block, for the base case...
  font-size: 16px;
  font-weight: 600;
  font-family: $headline-font;
  line-height: 120%;
  @include hover-fade();
  @include breakpoint($bp-alpha) {
    min-width: 265px;
  }
  max-width: 350px;
  padding: 17px 15px;
  text-align: center;
  width: auto;
  @include breakpoint($bp-alpha, max) {
    width: 100%;
  }

  &:hover {
    background: $primary;
    color: #212121;
  }

  &.alt {
    background: $secondary;
    &:hover {
      background: $primary;
    }
  }
}
