// Utilities
@import 'utilities/mixins';
@import 'utilities/variables';

// base
@import 'base/sanitize';
@import 'base/base';
@import 'base/bootstrap';

// elements
// @import 'elements/hamburgers/settings';
// @import 'elements/hamburgers/hamburgers';
@import 'elements/font-awesome';
@import 'elements/buttons';
@import 'elements/forms';
@import 'elements/social';
@import 'elements/popup';
@import 'elements/overlays';
@import 'elements/fancybox';

//plugins
@import 'plugins/magnific';
@import 'plugins/we-mobilize';

// global
@import 'global/header';
@import 'global/footer';
@import 'global/sidebar';
@import 'global/typography';
@import 'global/layout';
@import 'global/topper';
@import 'global/modules';
@import 'global/effects/animations';

// pages
@import 'pages/home';
@import 'pages/poster';
// @import 'pages/landing';
// @import 'pages/issues';
// @import 'pages/news';
// @import 'pages/event';
// @import 'pages/list';

@import 'global/shame';

@import 'base/print';

//@import "pages/quiz";

// Sass to JS, used for Style Guide.
//@import "pages/style-guide";

// *, *:before, *:after {
//   transition: all 2.6s !important;
// }

.nolink {
  text-decoration: none;
}
