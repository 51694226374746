input[type='text'],
input[type='email'],
textarea {
  background: #fff;
  height: 50px;
  padding: 10px 20px;
  border-radius: 3px;
  @include transition(background 0.25s ease);
  float: left;
  width: 100%;
  outline: none;
  border: 1px solid #ccc;
  color: $body;
  font-size: 20px;
  font-family: $primary-font;
  @include box-shadow(
    $boxShadowTop,
    $boxShadowLeft,
    $boxShadowBlur,
    $boxShadowColor
  );

  &:focus {
    background: #eee;
  }

  &::-webkit-input-placeholder {
    color: $body;
  }

  &::-moz-placeholder {
    color: $body;
  }

  &:-ms-input-placeholder {
    color: $body;
  }

  &:-moz-placeholder {
    color: $body;
  }
}

input[type='submit'] {
  &.btn {
    float: left;
    width: 100%;
  }
}

button {
  &.btn {
    float: left;
    width: 100%;
  }
}

body
  .gform_wrapper.gform_validation_error
  .gform_body
  ul
  li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
  max-width: 100% !important;
  width: 100% !important;
}

.action-form {
  #action_info,
  #logo_wrap {
    display: none;
  }
  #can_embed_form {
    background-color: transparent !important;
    padding: 0px !important;
    border: none !important;
  }
  #can_embed_form {
    #can_embed_form_inner {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        display: none;
      }
    }
    .country_wrap {
      display: none;
    }
    #d_sharing {
      display: none !important;
    }
    input {
      margin-bottom: 4px !important;
    }
    input[type='text'],
    input[type='password'],
    input[type='url'],
    input[type='email'],
    input[type='tel'],
    input[type='number'],
    textarea {
      background: #fff !important;
      height: 50px !important;
      padding: 10px 20px !important;
      border-radius: 3px !important;
      @include transition(background 0.25s ease !important);
      outline: none !important;
      border: 1px solid #ccc !important;
      color: $body !important;
      font-size: 20px !important;
      font-family: $primary-font !important;
      @include box-shadow(
        $boxShadowTop,
        $boxShadowLeft,
        $boxShadowBlur,
        $boxShadowColor
      );
      &:focus {
        background: #eee !important;
      }
      &::-webkit-input-placeholder {
        color: $body !important;
      }
      &::-moz-placeholder {
        color: $body !important;
      }
      &:-ms-input-placeholder {
        color: $body !important;
      }
      &:-moz-placeholder {
        color: $body !important;
      }
    }
    input[type='submit'] {
      display: table !important;
      @include hover-fade();
      cursor: pointer !important;
      background: $primary !important;
      color: #fff !important;
      padding: 17px 15px !important;
      border-radius: 3px !important;
      line-height: 120% !important;
      min-width: 300px !important;
      max-width: 350px !important;
      width: auto !important;
      font-size: 16px !important;
      font-weight: 600 !important;
      font-family: $headline-font !important;
      text-shadow: none !important;
      text-align: center;
      margin: 11px auto 0px !important;
      @include box-shadow(
        $boxShadowTop,
        $boxShadowLeft,
        $boxShadowBlur,
        $boxShadowColor
      );
      &:hover {
        background: $secondary !important;
        color: #fff !important;
      }
    }
  }
}

.content {
  .gform_wrapper {
    margin-top: 35px;
    .gform_body {
      width: 100% !important;
    }
    .gfield_label {
      margin-top: 0 !important;
      font-family: $headline-font;
      color: $headlineColor;
      font-weight: 600;
      font-size: 16px;
      line-height: 130%;
      margin: 0 0 0.5em;
      @include breakpoint($bp-charlie) {
        font-size: 18px;
      }
      @include breakpoint($bp-bobby) {
        font-size: 20px;
      }
    }
    .validation_error {
      color: #ff3232;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 25px;
      border: 1px solid #ff3232;
      padding: 10px 0;
      clear: both;
      width: 100%;
      text-align: center;
    }
    li.gfield.gfield_error,
    .gform_wrapper
      li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
      background: transparent;
      border-top: none;
      border-bottom: none;
      .gfield_label,
      .gfield_required {
        color: $body;
      }
      .gfield_label {
        margin-top: 0 !important;
        font-family: $headline-font;
        color: $headlineColor;
        font-weight: 600;
        font-size: 16px;
        line-height: 130%;
        margin: 0 0 0.5em;
        @include breakpoint($bp-charlie) {
          font-size: 18px;
        }
        @include breakpoint($bp-bobby) {
          font-size: 20px;
        }
      }
      .gfield_description.validation_message {
        margin-top: 0;
        padding-top: 0;
        color: #ff3232;
      }
      input:not([type='radio']):not([type='checkbox']):not([type='submit']):not([type='button']):not([type='image']):not([type='file']),
      .gform_wrapper li.gfield_error textarea {
        border: 1px solid #ff3232 !important;
      }
    }
    #gform_ajax_spinner_1 {
      margin: 15px auto;
      float: none;
      display: table;
      max-width: 25px !important;
      max-height: 25px !important;
    }
    ul {
      &.gform_fields {
        li {
          &.gfield {
            padding-right: 0px;
            float: left;
            width: 100%;
            max-width: 100%;
            margin: 0 0 20px;
          }
          input[type='text'],
          input[type='email'],
          textarea {
            border: 1px solid #ccc;
          }
        }
      }
    }
    .gform_footer {
      margin: 0px;
      padding: 0px;
      input[type='submit'] {
        display: table;
        width: auto;
        @include hover-fade();
        cursor: pointer;
        background: $primary;
        color: #fff;
        padding: 17px 15px;
        border-radius: 3px;
        line-height: 120%;
        min-width: 300px;
        max-width: 350px;
        width: auto;
        font-size: 16px;
        font-weight: 600;
        font-family: $headline-font;
        text-align: center;
        margin: 0 auto;
        @include box-shadow(
          $boxShadowTop,
          $boxShadowLeft,
          $boxShadowBlur,
          $boxShadowColor
        );
        &:hover {
          background: $secondary;
          color: #fff;
        }
      }
    }
  }
}

.ngp-form {
  .at-inner {
    background-color: transparent !important;
    border: none !important;
    margin-top: 10px !important;
  }
  .at-title {
    display: none;
  }
  .at-fieldset {
    padding: 0px !important;
  }
  .at-fields {
    input[type='text'],
    input[type='tel'],
    input[type='email'],
    textarea {
      background: #fff;
      height: 50px;
      padding: 10px 20px;
      border-radius: 3px;
      @include transition(background 0.25s ease);
      float: left;
      width: 100%;
      outline: none;
      border: 1px solid #ccc;
      color: $body;
      font-size: 20px;
      font-family: $primary-font;
      @include box-shadow(
        $boxShadowTop,
        $boxShadowLeft,
        $boxShadowBlur,
        $boxShadowColor
      );
      margin: -14px 0 6px !important;
      &:hover {
        border: 1px solid #ccc;
      }
      &:focus {
        background: #eee;
        box-shadow: none;
        border: 1px solid #ccc;
      }
      &::-webkit-input-placeholder {
        color: $body;
      }
      &::-moz-placeholder {
        color: $body;
      }
      &:-ms-input-placeholder {
        color: $body;
      }
      &:-moz-placeholder {
        color: $body;
      }
    }
    label {
      margin: 0px;
      font-family: $primary-font;
      color: rgba(255, 255, 255, 0) !important;
      line-height: 1;
      margin-top: 0px;
      u {
        text-decoration: none;
      }
      &.FirstName,
      &.LastName,
      &.PostalCode,
      &.EmailAddress,
      &.MobilePhone {
        transition: opacity 0.5s;
        opacity: 0;
        &.show {
          opacity: 1;
        }
      }
    }
  }
  // Re-arrange the fields; NGP uses Flexbox in the form, so Flexbox is safe to use here
  .at-fields {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -moz-flex-direction: column;
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  .at-row.PostalCode {
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
  }
  .at-row.EmailAddress {
    -webkit-box-ordinal-group: 1;
    -moz-box-ordinal-group: 1;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  .at-markup {
    &.HeaderHtml {
      display: none;
    }
    &.SmsLegalDisclaimer {
      &.at-legal {
        margin: 0px;
        margin-top: 10px;
        p {
          font-size: 11px;
        }
      }
    }
  }
  .at-form-submit {
    padding: 0px;
    .at-submit {
      display: table;
      width: auto;
      @include hover-fade();
      cursor: pointer;
      background: $primary;
      color: #fff;
      padding: 17px 15px;
      border-radius: 3px;
      line-height: 120%;
      min-width: 300px;
      max-width: 350px;
      width: auto;
      font-size: 16px;
      font-weight: 600;
      font-family: $headline-font;
      text-align: center;
      margin: 0 auto;
      @include box-shadow(
        $boxShadowTop,
        $boxShadowLeft,
        $boxShadowBlur,
        $boxShadowColor
      );
      &:hover {
        background: $secondary;
        color: #fff;
      }
    }
  }
  .error {
    padding: 0px !important;
    background: transparent !important;
    position: relative !important;
    top: 0px !important;
    margin-bottom: 0px !important;
  }
  legend {
    display: none;
  }
  .ngp-form-view {
    .ngp-form-inner {
      form.control-group {
        .form-item {
          float: left;
          width: 100%;
          margin-bottom: 7px;
          &:last-of-type {
            margin-bottom: 12px;
          }
          label {
            color: #fff;
            font-size: 22px;
            font-family: $primary-font;
            display: none;
          }
          input {
            margin: 0;
          }
        }
        .ngp-form-submit {
          .form-submit.btn {
            color: #fff;
            border-color: #fff;
            &:hover {
              color: $primary;
              background-color: #fff;
            }
          }
        }
      }
    }
  }
}

.donation-caption {
  p {
    font-size: 14px;
    font-style: italic;
    color: white;
  }
}
