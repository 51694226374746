.nopositionsticky {
  .main-header {
    background: $body;
  }

  //apply rules when header is sticky
  .is-sticky.top {
    top: 0px;
    position: fixed;
    width: 100%;
  }
}

.admin-bar {
  // .top {
  //  top: 46px;
  //  @include breakpoint($bp-charlie) {
  //    top: 32px;
  //  }
  // }
  //
  // &.sticky {
  //  .top {
  //    @include breakpoint($bp-charlie) {
  //      top: 32px;
  //    }
  //  }
  // }
}
