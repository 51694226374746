/* Overlays */

$overlay-background-color: rgba(
  #20202f,
  0.98
) !default; // controlled in variables

/*hide on no js cases*/
.no-js {
  .searchOverlay,
  .menuOverlay {
    display: none;
  }
}

.searchOverlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: $overlay-background-color;
  z-index: 9999 !important;
}

.js .searchOverlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}

.searchOverlay::before,
.searchOverlay::after {
  content: '';
  position: absolute;
  width: calc(100% + 15px);
  height: calc(100% + 15px);
  pointer-events: none;
  border: 1em solid #eceff1;
}

.searchOverlay::before {
  top: 0;
  left: 0;
  border-right-width: 0;
  border-bottom-width: 0;
}

.searchOverlay::after {
  right: 0;
  bottom: 0;
  border-top-width: 0;
  border-left-width: 0;
}

.btn--search-close {
  font-size: 2em;
  position: absolute;
  top: 1em;
  right: 1.25em;
  display: none;
  cursor: pointer;
}

.js .btn--search-close {
  display: block;
}

.search__form {
  margin: 5em 0;
}

.search__input {
  font-family: inherit;
  font-size: 6vw;
  line-height: 1;
  display: inline-block;
  box-sizing: border-box;
  width: 90%;
  padding: 0.05em 0;
  color: #eceff1;
  border-bottom: 2px solid $primary;
  font-family: $headline-font;
  font-weight: 400;

  /* Remove Firefox focus ring */
  &:focus {
    outline: none;
  }

  @include breakpoint($bp-bravo) {
    min-width: 325px;
    width: 60%;
  }
}

.search__input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  opacity: 0.85;
  color: #eceff1;
}

.search__input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  opacity: 0.85;
  color: #eceff1;
}

.search__input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  opacity: 0.85;
  color: #eceff1;
}

.search__input::-webkit-search-cancel-button,
.search__input::-webkit-search-decoration {
  -webkit-appearance: none;
}

.search__input::-ms-clear {
  display: none;
}

.search__info {
  font-size: 80%;
  font-weight: bold;
  display: block;
  width: 90%;
  margin: 0 auto;
  padding: 0.65em 0;
  text-align: right;
  color: $primary;
  font-style: $headline-font;

  @include breakpoint($bp-bravo) {
    min-width: 325px;
    width: 60%;
  }
}

.search__related {
  display: flex;
  width: 75%;
  pointer-events: none;
}

.search__suggestion {
  width: 33.33%;
  text-align: left;
}

.search__suggestion:nth-child(2) {
  margin: 0 3em;
}

.search__suggestion h3 {
  font-size: 1.35em;
  margin: 0;
}

.search__suggestion h3::before {
  content: '\21FE';
  display: inline-block;
  padding: 0 0.5em 0 0;
}

.search__suggestion p {
  font-size: 1.15em;
  line-height: 1.4;
  margin: 0.75em 0 0 0;
}

/************************/
/* Transitions 			*/
/************************/

.js .searchOverlay {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.5s;
}

.js .search--open {
  pointer-events: auto;
  opacity: 1;
  z-index: 99999;
}

/* Border */
.searchOverlay::before,
.searchOverlay::after {
  transition: transform 0.5s;
}

.searchOverlay::before {
  transform: translate3d(-15px, -15px, 0);
}

.searchOverlay::after {
  transform: translate3d(15px, 15px, 0);
}

.search--open::before,
.search--open::after {
  transform: translate3d(0, 0, 0);
}

/* Close button */
.btn--search-close {
  opacity: 0;
  transform: scale3d(0.8, 0.8, 1);
  transition: opacity 0.5s, transform 0.5s;
  svg {
    width: 35px;
    .color {
      fill: #eceff1;
      @include transition(all 0.2s ease);
    }
  }
  &:hover {
    svg .color {
      fill: $primary;
    }
  }
}

.search--open .btn--search-close {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

/* Search form with input and description */
.js .search__form {
  opacity: 0;
  transform: scale3d(0.8, 0.8, 1);
  transition: opacity 0.5s, transform 0.5s;
}

.js .search--open .search__form {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

.search__suggestion {
  opacity: 0;
  transform: translate3d(0, -30px, 0);
  transition: opacity 0.5s, transform 0.5s;
}

.search--open .search__suggestion {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.search--open .search__suggestion:nth-child(2) {
  transition-delay: 0.1s;
}

.search--open .search__suggestion:nth-child(3) {
  transition-delay: 0.2s;
}

@media screen and (max-width: 40em) {
  .search__form {
    margin: 5em 0 1em;
  }
  .btn--search-close {
    font-size: 1.25em;
    top: 1.5em;
    right: 1.5em;
    svg {
      width: 25px;
    }
  }
  .search__info {
    text-align: left;
  }
  .search__suggestion {
    font-size: 80%;
    width: 100%;
  }
  .search__suggestion:nth-child(2),
  .search__suggestion:nth-child(3) {
    display: none;
  }
}

/* Layout for menu container */
.menuOverlay {
  background: $overlay-background-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .social-mobile {
    display: block;
    width: 100%;
    text-align: center;

    .social.links {
      float: none;
      display: inline-block;
      margin-top: 0;
      width: auto;
      ul {
        li {
          a {
            font-size: 24px;
            @include breakpoint($bp-bravo) {
              font-size: 29px;
            }
            @include breakpoint($bp-charlie) {
              font-size: 35px;
            }
            color: $primary;
            opacity: 1;
            &:hover {
              color: $navColor;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
.main-mobile-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  flex: 1 0 auto;

  .menu-primary-navigation-container {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
  }

  ul.menu {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      font-size: 25px;
      line-height: 130%;
      @include breakpoint($bp-bravo) {
        font-size: 28px;
      }
      @include breakpoint($bp-charlie) {
        font-size: 38px;
      }
      a {
        font-family: $headline-font;
        color: $navColor;
        padding: 5px;
        display: block;
        &:hover {
          color: $navColor;
          background: $primary;
        }
      }
    }
  }
  nav {
    &.secondary {
      display: block;
      text-align: center;
      float: left;
      width: 100%;
      margin: 40px auto;
      ul {
        display: block;
        margin: 0 auto;
        float: none;
        li {
          float: none;
          display: block;
          width: 100%;
          max-width: 275px;
          margin: 0 auto !important;
          text-align: center;
          a {
            display: inline-block;
            height: 50px;
            line-height: 48px;
            float: none;
            padding: initial;
            width: 100%;
            max-width: 95%;
            margin: 0 auto;
          }
        }
        li + li {
          a {
            margin-top: 10px;
          }
        }
      }
    }
  }
}
.no-js .menuOverlay {
  display: none;
}
.js .menuOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 50px;
  min-height: 100%;
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
}
.overlay-active {
  body {
    overflow: hidden;
  }
  #container-wrap {
    max-height: 100vh;
    overflow: hidden;
  }
  .alert-bar {
    display: none !important;
  }
}

.mobile-nav-footer {
  color: $navColor;
  flex: 0 1 0;
  //margin-bottom: 20px;
}

.menuOverlay::before,
.menuOverlay::after {
  content: '';
  position: absolute;
  width: calc(100% + 15px);
  height: calc(100% + 15px);
  pointer-events: none;
}

.menuOverlay::before {
  top: 0;
  left: 0;
  border-right-width: 0;
  border-bottom-width: 0;
}

.menuOverlay::after {
  right: 0;
  bottom: 0;
  border-top-width: 0;
  border-left-width: 0;
}

.btn--menu-close {
  font-size: 1em;
  position: absolute;
  top: 1em;
  right: 1.25em;
  display: none;
  cursor: pointer;
  z-index: 99;
  -webkit-tap-highlight-color: transparent;
  @include breakpoint($bp-charlie) {
    font-size: 2em;
  }
}

.js .btn--menu-close {
  display: block;
}

.menu__form {
  margin: 5em 0;
}

.menu__input {
  font-family: inherit;
  font-size: 10vw;
  line-height: 1;
  display: inline-block;
  box-sizing: border-box;
  width: 75%;
  padding: 0.05em 0;
  color: #eceff1;
  border-bottom: 2px solid $primary;
}

.menu__input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  opacity: 0.85;
  color: #eceff1;
}

.menu__input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  opacity: 0.85;
  color: #eceff1;
}

.menu__input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  opacity: 0.85;
  color: #eceff1;
}

.menu__input::-webkit-menu-cancel-button,
.menu__input::-webkit-menu-decoration {
  -webkit-appearance: none;
}

.menu__input::-ms-clear {
  display: none;
}

.menu__info {
  font-size: 90%;
  font-weight: bold;
  display: block;
  width: 75%;
  margin: 0 auto;
  padding: 0.85em 0;
  text-align: right;
  color: $primary;
  font-style: $headline-font;
}

.menu__related {
  display: flex;
  width: 75%;
  pointer-events: none;
}

.menu__suggestion {
  width: 33.33%;
  text-align: left;
}

.menu__suggestion:nth-child(2) {
  margin: 0 3em;
}

.menu__suggestion h3 {
  font-size: 1.35em;
  margin: 0;
}

.menu__suggestion h3::before {
  content: '\21FE';
  display: inline-block;
  padding: 0 0.5em 0 0;
}

.menu__suggestion p {
  font-size: 1.15em;
  line-height: 1.4;
  margin: 0.75em 0 0 0;
}

/************************/
/* Transitions 			*/
/************************/

.js .menuOverlay {
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.5s;
}

.js .menu--open {
  pointer-events: auto;
  opacity: 1;
  z-index: 9999;
}

/* Border */
.menuOverlay::before,
.menuOverlay::after {
  transition: transform 0.5s;
}

.menuOverlay::before {
  transform: translate3d(-15px, -15px, 0);
}

.menuOverlay::after {
  transform: translate3d(15px, 15px, 0);
}

.menu--open::before,
.menu--open::after {
  transform: translate3d(0, 0, 0);
}

/* Close button */
.btn--menu-close {
  opacity: 0;
  transform: scale3d(0.8, 0.8, 1);
  transition: opacity 0.5s, transform 0.5s;
  svg {
    width: 25px;
    .color {
      fill: #eceff1;
      @include transition(all 0.2s ease);
    }
    @include breakpoint($bp-charlie) {
      width: 35px;
    }
  }
  &:hover {
    svg .color {
      fill: $primary;
    }
  }
}

.menu--open .btn--menu-close {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

/* menu form with input and description */
.js .main-mobile-menu {
  opacity: 0;
  transform: scale3d(0.8, 0.8, 1);
  transition: opacity 0.5s, transform 0.5s;
}

.js .menu--open .main-mobile-menu {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

@media screen and (max-width: 40em) {
  .js .menuOverlay {
    padding: 5em 0 1em;
  }
  .btn--menu-close {
    font-size: 1.25em;
    top: 0;
    right: 0;
    padding: 1.55em 1.5em 0.5em 0.5em;
    svg {
      width: 25px;
    }
  }
  .menu__info {
    text-align: left;
  }
}

.js .temp-high-z {
  z-index: 99999 !important;
}
