@import 'header/alert-bar.scss'; // new alert bar
@import 'header/nopositionsticky.scss'; // special rules for browsers that DO NOT support position: sticky
// @import 'header/hide-wp-adminbar.scss'; // uncomment and include this if wp-admin bar needs to be permanently hidden on all pages

// Header
.top {
  position: relative;
  top: 0;
  z-index: 9998;
  opacity: 1;
  width: 100%;
  //@include transition(all 0.3s); //no unecessary transation
}

//any special rules for the nav when the sticky scrollpoint is hit
//if the body needs rules, then javascript would need to be altered to apply a class to the body
.is-sticky {
}

.pusher {
  height: 0px;
  @include transition(all 0.3s);
}

body.search-open {
  .pusher {
    margin-top: 0px;
  }
  .top {
    top: 61px;
  }
  .top-search {
    top: 0;
    opacity: 1;
  }
}

.top-search {
  height: 61px;
  background: #eee;
  padding: 0px 0;
  position: fixed;
  top: 0;
  width: 100%;
  top: -61px;
  @include transition(all 0.3s);
  z-index: 9999;
  opacity: 0;
  .container {
    padding: 0;
  }
  form {
    position: relative;
    height: 61px;
    display: block;
    float: left;
    width: 100%;
  }
  .container {
    width: 100%;
    max-width: 1195px;
  }
  .arrow {
    position: absolute;
    right: 23px;
    top: 50%;
    @include transform(translateY(-50%));
    svg {
      width: 22px;
      color: $body;
      @include hover-fade();
      cursor: pointer;
      &:hover {
        color: $secondary;
      }
    }
  }
  input[type='text'] {
    width: 100%;
    height: 61px;
    background: #eee;
    color: $body;
    font-family: $headline-font;
    font-weight: 400;
    font-size: 29px;
    line-height: 61px;
    letter-spacing: 0;
    opacity: 1;
    padding: 0px 60px 00px 10px;
    border: none !important;
    @include transition(all 0.3s);
    box-shadow: none !important;
    position: relative;
    top: 47%;
    @include transform(translateY(-50%));
    &::-webkit-input-placeholder {
      top: 0%;
      position: relative;
      color: $body;
    }
    &::-moz-placeholder {
      top: 0%;
      position: relative;
      color: $body;
    }
    &:-ms-input-placeholder {
      top: 0%;
      position: relative;
      color: $body;
    }
    &:-moz-placeholder {
      top: 0%;
      position: relative;
      color: $body;
    }
  }
  input[type='submit'] {
    display: none;
  }
}

$header-v-padding-desktop: 14px;

.main-header {
  width: 100%;
  background: #fff;
  position: relative;
  padding: 35px 0 35px 0;
  @include breakpoint($bp-charlie) {
    height: 120px;
    padding: 56px 0 0 0;
  }
  .main-logo {
    display: inline-block;
  }
  .logo {
    img,
    svg {
      display: inline-block;
      width: 192px;
      height: 20px;
      margin: 3px 0 0 0;
      @include hover-fade();
      max-height: $header-height - ($header-v-padding-desktop * 2);
    }
    svg {
      .st0 {
        fill: #1153e9;
      }
      .st1 {
        fill: #e80300;
      }
      .st2 {
        fill: #1254e9;
      }
    }
  }
  .control {
    float: left;
    width: 100%;
    margin-top: 15px;
    @include breakpoint(420px) {
      float: right;
      width: 50%;
      margin-top: 0;
    }
  }
  .social {
    &.links {
      margin-top: 0;
      float: left;
      @include breakpoint(475px) {
        
        float: right;
      }
      @include breakpoint(768px) {
        padding-right: 16px;
      }
      @include breakpoint(920px) {
        padding-right: 0px;
        margin-right: -6px;
      }
      @include breakpoint($bp-bobby) {
        //margin-right: 20px;
        margin-right: 0px;
      }

      @include breakpoint($bp-echo) {
        @include hover-fade();
        color: #fff;
      }
      ul {
        @include breakpoint($bp-echo) {
          float: none;
        }

        &:before {
          float: left;
          display: inline-block;
          padding-right: 7px;
          content: 'Share';
          text-transform: uppercase;
          font-size: 0.6rem;
          color: rgb(153, 153, 153);
        }
      }
      li {
        i {
          font-size: 17px;
        }
        a {
          color: $socialColor;
          padding: 0 7px;
          &:hover {
            color: $socialHover;
          }
        }
      }

      .fa-envelope {
        font-size: 14px;
        transform: translateY(-2px);
      }
    }
  }
  nav {
    float: right;
    &.primary {
      a {
        color: #181616;
        &:hover {
          color: $secondary;
        }
      }
    }
  }
}

// Navigation
nav {
  &.primary {
    display: block;
    float: left;
    margin: 0 0 0 0;
    @include hover-fade();
    @include breakpoint(420px) {
      //float: right;
    }
    @include breakpoint(768px) {
      float: right;
    }
    ul {
      margin: 0 0 10px 0;
      display: table;
      @include breakpoint(420px) {
        //margin: 0 0 0px 20px;
      }
      @include breakpoint(768px) {
        margin: -14px 0 0px 20px;
      }
      @include breakpoint(1040px) {
        margin: 0px 20px 0px 0px;
      }
      @include breakpoint(1160px) {
        margin: 0px;
      }
      li {
        margin: 0;
        padding: 0;
        position: relative;
        font-family: $headline-font;
        font-size: 14px;
        font-weight: 700;
        text-decoration: underline;
        @include breakpoint($bp-echo) {
          padding: 0;
        }
        a {
          color: #181616;
        }
        &.home {
          display: none;
        }
        &.menu-item-has-children {
          &:hover {
            .sub-menu {
              display: block;
              visibility: visible;
              margin-top: 0;
              opacity: 1;
              li {
                -webkit-animation: fadeIn 0.5s ease-in-out;
                -webkit-animation-fill-mode: both;
                animation: fadeIn 0.5s ease-in-out;
                animation-fill-mode: both;
              }
            }
          }
        }
        &:first-child {
          border-left: 0px;
        }
        &:last-child {
          margin-right: 0px;
        }
        .sub-menu {
          width: 270px;
          position: absolute;
          top: 65px;
          left: 58%;
          -webkit-transform: translateX(-50%);
          -ms-transform: translateX(-50%);
          transform: translateX(-50%);
          background: rgba($dropDownBg, 1);
          padding: 25px;
          visibility: hidden;
          opacity: 0;
          margin-top: 20px;
          @include hover-fade();
          @include box-shadow(
            $boxShadowTop,
            $boxShadowLeft,
            $boxShadowBlur,
            $boxShadowColor
          );
          &:before {
            content: '';
            width: 0;
            height: 0;
            border-left: 11px solid transparent;
            border-right: 11px solid transparent;
            border-bottom: 12px solid rgba($dropDownBg, 1);
            position: absolute;
            top: -12px;
            left: 0;
            right: 0;
            margin: 0 auto;
          }
          &:after {
            content: '';
            height: 22px;
            width: 100%;
            position: absolute;
            top: -22px;
            left: 0;
            background: transparent;
          }
          li {
            width: 100%;
            margin: 0 0 8px 0;
            padding: 0;
            border: 0 !important;
            line-height: 100%;
            font-family: $headline-font;
            font-weight: 700;
            font-size: 14px;
            &:last-child {
              margin-bottom: 0 !important;
            }
            a {
              color: $dropDownColor;
              margin: 0;
              &:hover {
                color: $dropDownHover;
                opacity: 1 !important;
              }
            }
          }
        }
      }
    }
    /* classes that can be used to style nav when # of items gets really long*/
    &.primary.over-five-items {
    }
    &.primary.over-six-items {
    }
    &.primary.over-fourty-characters {
      // & > ul > li{
      // 	padding: 11px 10px;
      // 	font-size: 14px;
      // }
    }
    &.primary.over-fifty-characters {
    }
  }
}

.float-link {
  position: fixed;
  width: 100%;
  background: $primary;
  right: 0;
  bottom: 0;
  height: 40px;
  z-index: 9999;
  @include transition(background 0.3s ease);
  text-align: center;
  padding-top: 7px;
  @include breakpoint($bp-charlie) {
    position: fixed;
    padding-top: 0;
    width: 35px;
    top: 55px;
    bottom: auto;
    height: 150px;
  }
  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    @include transition(background 0.3s ease);
  }
  &:hover {
    background: $secondary;
  }
  .arrow {
    width: 15px;
    height: 11px;
    display: inline-block;
    margin-left: 10px;
    @include breakpoint($bp-charlie) {
      position: absolute;
      bottom: 22px;
      margin-left: 0;
      left: 3px;
      right: 0;
      margin: 0 auto;
    }
    svg {
      fill: #fff;
      width: 15px;
      height: 11px;
    }
  }
  .wrap-text {
    display: inline-block;
    @include breakpoint($bp-charlie) {
      position: absolute;
      left: 6px;
      top: -15px;
      transform-origin: left bottom 0;
      z-index: 4;
      @include transform(rotate(90deg));
    }
    span {
      font-size: 16px;
      font-family: $primary-font;
      font-weight: 700;
      color: #fff;
      @include breakpoint($bp-charlie) {
        @include transform(rotate(-90deg));
        white-space: nowrap;
        font-size: 15px;
      }
    }
  }
}
