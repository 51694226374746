$beat-one: 0.2s;
$beat-two: 0.4s;
$beat-three: 0.6s;
$beat-four: 1s;

$animation-duration: 0.35s !default;
$animation-breakpoint: $bp-delta; // currently 960px, see animations.js, which should match this

%will-animate-in-via-opacity {
  .no-js & {
    opacity: 0;
    animation: fadescale-in $animation-duration ease-in-out 1 forwards;
  }
  @include breakpoint($animation-breakpoint) {
    .js & {
      opacity: 0;
      will-change: opacity;
    }
    // animations.js doesn't fire events on resize, so this ensures items will be visible when going from thin to wide widths
    .js.was-resized & {
      opacity: 1;
    }
  }
}

//sub-items that should fade to full opacity in sequence
.no-js .topper {
  h6,
  h1,
  form,
  .topper-form,
  .deck,
  .read-more {
    @extend %will-animate-in-via-opacity;
    perspective: 3000px;
  }
  h1 {
    animation-delay: $beat-one;
  }
  form {
    animation-delay: $beat-two;
  }
  .topper-form,
  .deck {
    animation-delay: $beat-three;
  }
  .read-more {
    animation-delay: $beat-four;
  }
}

//sub-items that should fade to full opacity in sequence
.issues-grid .single-issue,
.news-items .news-item {
  @extend %will-animate-in-via-opacity;
}

@keyframes fadescale-in {
  from {
    opacity: 0;
    transform: rotateX(-15deg) scale(1.2);
  }
  to {
    opacity: 1;
    transform: rotate(0) scale(1);
  }
}
